import { useState, useCallback } from "react"
import ListTitleLoader from "./ListTitleLoader"
import FilterLoader from "./FilterLoader"
import { TableCellLoader } from "@dnr/features/shared/tables"

export const PageListLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref}>
            <ListTitleLoader loaderWidth={loaderWidth} />
            <FilterLoader />

            <div className="c-cards c-cards--full">
                <TableCellLoader />
            </div>
        </div>
    )
}

export default PageListLoader
