import {
    ApiResponseModel,
    CreateReceivingTerminal,
    IPagedCollection,
    IRequestOptions,
    ReceivingTerminal,
    ReceivingTerminalCacheEntry,
    ReceivingTerminalFilter,
} from "@dnr/data/models"
import { localStorageProvider } from "@dnr/util/storage"
import { DateTime } from "luxon"

import { ServiceBase } from "../common-services/ServiceBase"

class TerminalService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "TerminalService"
    }

    async getAllCached(
        warehouseIds?: string[],
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ReceivingTerminal>>> {
        // In case there's some filtering, we'll always go to the API for the results
        if (warehouseIds && warehouseIds.length > 0) {
            const result = await this.find({ warehouseIds: warehouseIds, pageNumber: 1, pageSize: 1000 }, options)
            return result
        }

        const cachedValues = localStorageProvider.get<ReceivingTerminalCacheEntry>("receiving-terminal-cache")

        if (
            !cachedValues?.value?.entries ||
            cachedValues.value.createdAt < DateTime.now().minus({ minutes: 10 }).toJSDate()
        ) {
            const response = await this.find(undefined, options)

            if (response) {
                localStorageProvider.set("receiving-terminal-cache", {
                    createdAt: DateTime.now().toJSDate(),
                    entries: response.result,
                })
            }

            return response
        } else if (cachedValues?.value?.entries) {
            return {
                result: cachedValues.value.entries,
                resultType: 1,
                errors: [],
                messages: [],
            }
        }

        return {
            result: {
                hasNextPage: false,
                items: [],
                pageCount: 0,
                pageNumber: 0,
                pageSize: 0,
                totalRecords: 0
            },
            resultType: 3,
            errors: [],
            messages: [],
        }
    }

    async find(query?: ReceivingTerminalFilter, options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<ReceivingTerminal>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ReceivingTerminal>>>("/receivingterminals", {
            signal: options?.abortController.signal,
            params: query,
        })
        return response.data
    }

    async get(receivingTerminalId: string, options?: IRequestOptions): Promise<ReceivingTerminal> {
        const response = await this.http.instance.get<ReceivingTerminal>(`/receivingterminals/${receivingTerminalId}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async put(receivingTerminalId: string, updatedTerminal: CreateReceivingTerminal): Promise<ReceivingTerminal> {
        const response = await this.http.instance.put<ReceivingTerminal>(
            `/receivingterminals/${receivingTerminalId}`,
            updatedTerminal
        )
        return response.data
    }

    async post(receivingTerminal: CreateReceivingTerminal): Promise<ReceivingTerminal> {
        const response = await this.http.instance.post<ReceivingTerminal>("/receivingterminals", receivingTerminal)
        return response.data
    }

    async delete(receivingTerminalId: string): Promise<void> {
        const response = await this.http.instance.delete(`/receivingterminals/${receivingTerminalId}`)
        return response.data
    }
}

export default new TerminalService()
