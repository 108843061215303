import React from "react"
import { BaseCard } from "./BaseCard"
import { CardContent } from "./CardContent"
import { CardFooter } from "./CardFooter"
import { CardHeader } from "./CardHeader"

type Props = {
    imgSrc?: string
    title?: string
    className?: string
    cardSize?: string
}

export const PrimaryCard: React.FC<Props> = (props) => {
    return (
        <BaseCard>
            <CardHeader {...props}>test</CardHeader>
            <CardContent {...props}>content</CardContent>
            <CardFooter {...props}>content</CardFooter>
        </BaseCard>
    )
}
