import { FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { useTranslate } from "@dnr/localization"

export interface FormOptionValue {
    label: string
    value: string | number
    id: string
}

export type FormOptionsProps<TForm extends FieldValues> = {
    label: string
    name: Path<TForm>
    errorMessage?: string
    options?: RegisterOptions<TForm>
    type?: string
    required?: boolean
    values: FormOptionValue[]
}

export const FormOptions = <TForm extends FieldValues>(props: FormOptionsProps<TForm>) => {
    const { label, name, errorMessage, options, required, values, ...inputProps } = props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, options)

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }
        return err
    }

    const isActive = (currentValue: string) => {
        const formValue = form.getValues(name)
        return formValue !== undefined && formValue !== null && formValue.toString() === currentValue
    }
    return (
        <div className={`${props.type === "default" ? "" : "u-mb--sml--2"}`}>
            <div>
                {label != null ? (
                    <label className="c-input__label">
                        {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                ) : null}
            </div>
            {form.formState.errors[name]?.message ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">
                        {translateErrorMessage(form.formState.errors[name]?.message?.toString())}
                    </span>
                </div>
            ) : null}

            <div className="c-pagination u-display--flex--gap--sml u-mt--sml--2 u-mb--sml--3">
                {values.map((item, index) => (
                    <div key={index} className={isActive(item.value.toString()) ? "active" : ""}>
                        <label
                            key={index}
                            className="c-pagination__page c-pagination__page--secondary"
                            htmlFor={item.id}
                        >
                            {item.label}
                        </label>
                        <input
                            type="radio"
                            id={item.id}
                            value={item.value}
                            className="u-display--none"
                            {...field}
                            {...inputProps}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
