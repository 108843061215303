import { useState } from "react"

import { helperService, productsService } from "@dnr/data/services"
import { useDidMountEffect, useRequest } from "@dnr/hooks"
import { SearchDropdownInput } from "@dnr/ui/inputs"

type IdentificationDropdownOption = {
    identificationId: string
    identificationTypeId: string
    displayText: string
}

export interface SearchByIdentificationDropdownProps {
    identificationType: "Upca" | "ModelNumber"
    required?: boolean
    className?: string
    placeholder: string
    label?: string
    extraLabel?: string
    selectedValue?: string
    color?: string
    reset?: number
    onChange: (value: IdentificationDropdownOption) => void
    onReset?: () => void
    type?: "select" | "default" | "up"
    size?: string
    width?: string
    env?: "portal" | "backoffice"
    fullWidth?: boolean
    isFilter?: boolean
}

export const SearchByIdentificationDropdown = (props: SearchByIdentificationDropdownProps) => {
    const { getProductIdentificationId } = helperService

    const [searchQuery, setSearchQuery] = useState("")
    const [selectedKey, setIsSelected] = useState(props.selectedValue)
    const [displayText, setDisplayText] = useState(props.placeholder)
    const [dropdownOptions, setDropdownOptions] = useState<IdentificationDropdownOption[]>([])
    const [loading, setLoading] = useState(true)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    useDidMountEffect(() => {
        handleReset()
    }, [props.reset])

    const handleReset = () => {
        setDisplayText(props.placeholder)
        setIsSelected(props.selectedValue)
        setSearchQuery("")
    }

    const handleChange = (element: IdentificationDropdownOption) => {
        props.onChange(element)
        setIsSelected(element.identificationId)
        setDisplayText(element.displayText)
    }

    const { error } = useRequest(
        async (options) => {
            if (isDropdownOpen) {
                setLoading(true)

                const response = await productsService.findFullProducts(
                    {
                        pageNumber: 1,
                        pageSize: 1000,
                        identificationType:
                            searchQuery && searchQuery.length > 0
                                ? getProductIdentificationId(props.identificationType).toString()
                                : undefined,
                        identificationValue: searchQuery && searchQuery.length > 0 ? searchQuery : undefined,
                    },
                    {
                        abortController: options.abortController,
                    }
                )

                const identifications = response.result.items.flatMap((item) => item.identifications)

                const result: IdentificationDropdownOption[] = identifications
                    .map((item) => {
                        return {
                            identificationId: item.identificationId,
                            identificationTypeId: item.identificationTypeId.toString(),
                            displayText: item.value,
                        }
                    })
                    .filter(
                        (item) =>
                            item.identificationTypeId ===
                            getProductIdentificationId(props.identificationType).toString()
                    )

                setDropdownOptions(result)
                setLoading(false)
            }
        },
        [searchQuery, isDropdownOpen],
        { debounce: 700 }
    )

    return (
        <SearchDropdownInput
            isOpenedFirstTime={(isOpened) => setIsDropdownOpen(isOpened)}
            displayText={displayText}
            options={dropdownOptions}
            handleChange={handleChange}
            handleReset={() => {
                handleReset()
                if (props.onReset) props.onReset()
            }}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            selectedValue={selectedKey}
            selectedKey="identificationId"
            color={props.color}
            isLight={props.color == "light" ? false : true}
            loading={loading}
            error={error}
            {...props}
            isFilter={props.isFilter}
        />
    )
}
