import { DeepPartial, FieldValues, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { OptionalObjectSchema } from "yup/lib/object"

interface HookFormParams<TData extends FieldValues> {
    validate?: boolean
    validationSchema?: OptionalObjectSchema<any>
    defaultValues?: DeepPartial<TData>
}

/**
 * Custom hook for using react-hook-form with typed params.
 *
 * @param params - The hook form parameters.
 * @returns The form object with typed data.
 */
export function useHookForm<TData extends FieldValues>(params: HookFormParams<TData>) {
    const { validate, validationSchema, defaultValues } = params
    const schema = validationSchema

    const form = useForm<TData>({
        resolver: validate && schema ? yupResolver(schema) : undefined,
        mode: "onSubmit",
        defaultValues,
    })

    return {
        form,
    }
}
