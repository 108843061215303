import { action, computed, makeObservable, observable } from "mobx"

import { TopicMessage } from "../models"
import { lookupService } from "@dnr/data/services"

export class TopicStore {
    messages = [] as TopicMessageStore[]
    currentUserId = ""
    isMessageSent?: boolean
    ticketStatus?: number
    resetState = false

    constructor(loggedInUserId: string) {
        this.currentUserId = loggedInUserId
        makeObservable(this, {
            messages: observable,
            isMessageSent: observable,
            ticketStatus: observable,
            resetState: observable,

            messageCount: computed,
            isTicketInProcessingStatus: computed,

            addNewMessage: action,
            refetch: action,
            setIsMessageSent: action,
            setMessages: action,
            setTicketStatus: action
        })
    }

    addNewMessage(value: TopicMessage) {
        this.messages.push(new TopicMessageStore(value, this.currentUserId))
    }

    refetch() {
        this.resetState = !this.resetState
    }

    setMessages(messages: TopicMessage[], currentUserId: string) {
        this.messages = messages.map((m) => new TopicMessageStore(m, this.currentUserId))
        this.currentUserId = currentUserId
    }

    setIsMessageSent() {
        if (!this.isTicketInProcessingStatus) this.isMessageSent = !this.isMessageSent
    }

    setTicketStatus(value: number) {
        this.ticketStatus = value
    }

    get messageCount() {
        return this.messages != null ? this.messages.length : 0
    }

    get isTicketInProcessingStatus() {
        if (this.ticketStatus) {
            return lookupService.getTopicStatusId()[this.ticketStatus].abrv === "processing"
        }
        return false
    }
}

export class TopicMessageStore {
    message: TopicMessage
    currentUserId: string

    constructor(message: TopicMessage, currentUserId: string) {
        this.message = message
        this.currentUserId = currentUserId

        makeObservable(this, {
            message: observable,

            isCurrentUsersMessage: computed,

            senderDisplayName: action,
            setMessage: action,
        })
    }

    setMessage(value: TopicMessage) {
        this.message = value
    }

    senderDisplayName(translate: (key: string, data?: object | undefined) => string) {
        return this.isCurrentUsersMessage
            ? translate("GENERAL.YOU")
            : this.message.sender
                ? `${this.message.sender.firstName} ${this.message.sender.lastName}`
                : translate("GENERAL.ANONYMOUS_USER")
    }

    get isCurrentUsersMessage() {
        return this.message.senderId === this.currentUserId
    }

}
