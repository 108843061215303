import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"

type OnHoldStatuses = {
    abrv: string
    display: string
}[]

interface ShippingReqOnHoldStatusDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    onReset?: () => void
    label?: string
    reset?: number
    fullWidth?: boolean
    isFilter?: boolean
}
export const ShippingReqOnHoldStatusDropdown = (props: ShippingReqOnHoldStatusDropdownProps) => {
    const { t } = useTranslate()

    const stockStatusOptions = useMemo(() => {
        const onHoldStatuses = [
            {
                abrv: "any",
                display: "Any",
            },
            {
                abrv: "onHold",
                display: "On hold",
            },
            {
                abrv: "notOnHold",
                display: "Not on hold",
            },
        ] as OnHoldStatuses
        const options = [] as DropdownOption[]
        for (const key of onHoldStatuses) {
            options.push({
                displayText: key.display,
                key: key.abrv,
                value: key.abrv,
            })
        }
        return options
    }, [])

    return (
        <div>
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    {" "}
                    <label className="c-input__label">{t.form(props.label)}</label>
                </div>
            ) : null}
            <Dropdown
                resetStates={props.reset}
                selectedValue={props.selectedValue}
                id="select-on-hold-status"
                type="select"
                placeholder={props.placeholder}
                onChange={(option) => props.onChange(option)}
                options={stockStatusOptions}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </div>
    )
}
