import clsx from "clsx"
import { observer } from "mobx-react"

import { useTranslate } from "@dnr/localization"

import { ItemLink } from "./ItemLink"
import { MainMenuItemModel } from "../models/MainMenuItemModel"
import { NavigationStore } from "../stores/NavigationStore"
import { useEffect, useRef, useState } from "react"
import { useOutletContext } from "react-router"
interface Props extends MainMenuItemModel {
    store?: NavigationStore
    env: string
}

export const MainMenuItem = observer((props: Props) => {
    const { t } = useTranslate()
    const [dropdownPosition, setDropdownPosition] = useState(0)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const menuStore = useOutletContext<NavigationStore>()

    useEffect(() => {
        if (dropdownRef.current !== null) {
            setDropdownPosition(dropdownRef.current.getBoundingClientRect().top)
        }
    }, [dropdownRef])

    const handleAction = () => {
        props.store?.setIsOpened(props.env, props.page, !props.isOpened)
        props.store?.setIsActive(props.env, props.page)
        if (menuStore.isMenuActive) menuStore.setIsMenuActive()
    }

    const handleOpen = () => {
       
        props.store?.setIsOpened(props.env, props.page, !props.isOpened)

        if (dropdownRef.current !== null) {
            setDropdownPosition(dropdownRef.current.getBoundingClientRect().top)
        }
    }

    const handlePageChange = (page: string | undefined) => {
        props.store?.setIsActive(props.env, page)
         if (menuStore.isMenuActive) menuStore.setIsMenuActive()

        if (props.store?.isCollapsed) {
            props.store?.setIsOpened(props.env, props.page, false)
        }

    }

    return (
        <div>
            {props.path !== null && props.path !== undefined ? (
                <ItemLink
                    label={t.common(props.label)}
                    icon={props.icon}
                    to={props.path}
                    type="primary"
                    onClick={handleAction}
                />
            ) : null}
            {props.children !== null && props.children !== undefined && props.children?.length > 0 ? (
                <>
                    <div
                        className={clsx(`c-menu__item ${props.isActive ? "active" : ""}`)}
                        onClick={handleOpen}
                        ref={dropdownRef}
                    >
                        <i className={`u-icon u-icon--base u-icon--${props.icon} u-mr--sml--2`} />

                        <p>
                            <span className="c-menu__item__label">{t.common(props.label)} </span>
                            {props.isOpened ? (
                                <i className="u-ml--sml--2 u-icon u-icon--med u-icon--arrow-up" />
                            ) : (
                                <i className="u-ml--sml--2 u-icon u-icon--med u-icon--arrow-down" />
                            )}
                        </p>
                    </div>

                    {props.isOpened ? (
                        <div
                            className={clsx(props.store?.isCollapsed ? "c-menu__list--fixed" : "c-menu__list")}
                            style={{
                                top: props.store?.isCollapsed && dropdownPosition ? dropdownPosition : "",
                            }}
                        >
                            {props.children.map((child, index) => {
                                return (
                                    <ItemLink
                                        key={index}
                                        childpage={child.childpage}
                                        label={t.common(child.label)}
                                        to={child.path || "/app"}
                                        type="secondary"
                                        action={(page) => handlePageChange(page)}
                                    />
                                )
                            })}
                        </div>
                    ) : null}
                </>
            ) : null}

            {props.split === true && <hr className="c-separator--color-xdark u-display--none--sml" />}
        </div>
    )
})
