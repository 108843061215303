import { HttpClientFactory, IHttpClient, ServiceBaseInitialOptions } from "@dnr/data/http"
import { ILogger, loggerFactory } from "@dnr/util/logger"
import { localStorageProvider } from "@dnr/util/storage"

export abstract class ServiceBase {
    abstract getLoggerName(): string

    protected http: IHttpClient
    protected logger: ILogger

    constructor(options?: ServiceBaseInitialOptions) {
        const httpClientFactory = new HttpClientFactory()

        this.http = httpClientFactory.create({
            ...options,
            baseUrl: process.env["NX_API_URL"]!,
            storage: localStorageProvider,
            throttle: process.env["NX_THROTTLE"]! === "true",
        })

        this.logger = loggerFactory.createLogger(this.getLoggerName())
    }
}
