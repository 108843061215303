import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { ButtonGroup, PrimaryButton, SecondaryButton, TertiaryButton } from "@dnr/ui/buttons"
import { helperService, lookupService, productsService } from "@dnr/data/services"
import { ImageCard } from "@dnr/ui/cards"
import { PrimaryEmptyState } from "@dnr/ui/empty-states"
import { Product } from "@dnr/features/portal/products"
import { LatestCommitmentsLoader } from "@dnr/ui/loaders"

interface HotDealsProps {
    displayCount: number
    imgSize?: "sml" | "base" | "med" | "lrg" | "xlrg"
}

export const HotDeals = (props: HotDealsProps) => {
    const [products, setProducts] = useState<Product[]>([])
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { currencyFormatter } = helperService

    const productPublishTypeIdByAbrv = (abrv: string) => {
        return lookupService.productPublishTypesByAbrv[abrv].id
    }

    const { loading } = useRequest(async (options) => {
        const { result: response } = await productsService.find(
            {
                pageNumber: 1,
                isHot: true,
                pageSize: props.displayCount,
                orderBy: "dateCreated|desc",
                publishTypeId: productPublishTypeIdByAbrv("publish"),
            },
            {
                abortController: options.abortController,
            }
        )

        setProducts(response.items)
    }, [])

    return loading ? (
        <LatestCommitmentsLoader />
    ) : (
        <>
            <div className="c-section__title">
                <p className="u-type--title">{t.common("DEAL.HOT_DEALS")}</p>
                <TertiaryButton size="base" haslabel onClick={() => navigate("/app/products/list")}>
                    {t.common("GENERAL.VIEW_ALL")}
                </TertiaryButton>
            </div>

            {products === null || products.length === 0 ? (
                <PrimaryEmptyState category="products" size="sml"></PrimaryEmptyState>
            ) : (
                <div className="c-section c-section--cards--sml c-section--cards--vertical">
                    {products.map((product) => (
                        <ImageCard
                            imageUrl={product.imageUrl || "../../assets/img/placeholders/pl-big.png"}
                            imageSize={props.imgSize ? props.imgSize : "sml"}
                            extendedClassName="c-cards--latest u-position--rel"
                            link={`/app/products/view/${product.productId}`}
                            linkText={product.name}
                            key={product.productId}
                            titleSize="lrg"
                            overlay
                            titleTagType={product.isHot ? "hot" : undefined}
                        >
                            {/* MSRP */}
                            <div className="c-cards__tag">{currencyFormatter(product.msrp)}</div>

                            <ButtonGroup>
                                <SecondaryButton
                                    haslabel
                                    size="xsml"
                                    onClick={() => navigate(`/app/products/view/${product.productId}`)}
                                >
                                    {t.common("GENERAL.VIEW")}
                                </SecondaryButton>
                                <PrimaryButton
                                    haslabel
                                    size="xsml"
                                    onClick={() => navigate(`/app/products/order-products/${product.productId}`)}
                                >
                                    {t.common("GENERAL.ORDER_NOW")}
                                </PrimaryButton>
                            </ButtonGroup>
                        </ImageCard>
                    ))}
                </div>
            )}
        </>
    )
}
