import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"
import { TableCellLoader } from "@dnr/features/shared/tables"

export const PreviewPageLoader = ({ className }: { className?: string }) => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref} className={className ? className : "c-container--lrg"}>
            {/* Header */}
            <LoaderWrapper width={loaderWidth} height={110} backgroundColor="#b7c6e5">
                {/* Breadcrumbs */}
                <rect x="0" y={0} rx="6" ry="6" width="100" height="12" />

                <rect x="110" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="130" y={0} rx="6" ry="6" width="140" height="12" />

                <rect x="280" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="300" y={0} rx="6" ry="6" width="400" height="12" />

                {/* Title */}
                <rect x="0" y={40} rx="6" ry="6" width="600" height="20" />
                <rect x="0" y={80} rx="6" ry="6" width="250" height="20" />
                <rect x="630" y={40} rx="10" ry="10" width="100" height="20" />

                <rect x={loaderWidth - 190} y={40} rx="6" ry="6" width="80" height="40" />
                <rect x={loaderWidth - 100} y={40} rx="6" ry="6" width="100" height="40" />
            </LoaderWrapper>

            {/* Card */}
            <div className="c-cards c-cards--full u-mb--sml--8">
                <LoaderWrapper width={loaderWidth} height={400}>
                    <rect x="0" y={0} rx="12" ry="12" width={Math.abs(loaderWidth - 460)} height="100%" />

                    <rect x={loaderWidth - 446} y={0} rx="6" ry="6" width="100" height="12" />
                    <rect x={loaderWidth - 446} y={30} rx="12" ry="12" width="430" height="136" />
                    <rect x={loaderWidth - 446} y={200} rx="6" ry="6" width="430" height="2" />

                    <rect x={loaderWidth - 446} y={230} rx="6" ry="6" width="100" height="12" />
                    <rect x={loaderWidth - 446} y={260} rx="12" ry="12" width="430" height="136" />
                </LoaderWrapper>
            </div>

            {/* Table title */}
            <LoaderWrapper width={loaderWidth} height={40} backgroundColor="#b7c6e5">
                <rect x="0" y={8} rx="6" ry="6" width="150" height="16" />
                <rect x={loaderWidth - 150} y={0} rx="6" ry="6" width="150" height="30" />
            </LoaderWrapper>

            {/* Table */}
            <div className="c-cards c-cards--full">
                <TableCellLoader />
            </div>
        </div>
    )
}

export default PreviewPageLoader
