import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import { useFormState } from "react-hook-form"

import { useTranslate } from "@dnr/localization"
import { PrimaryButton } from "@dnr/ui/buttons"

type Props = {
    children?: React.ReactNode
    displayText?: string
    overrideDisable?: boolean
    btnSize?: "sml" | "med" | "base" | "lrg"
    btnFull?: boolean
    hasicon?: boolean
    icontype?: string
    disabled?: boolean
    loading?: boolean
    // btnWidth?: "full" | ""
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const FormSubmit = ({
    children,
    className,
    displayText,
    btnSize,
    btnFull,
    hasicon,
    icontype,
    disabled = false,
    overrideDisable = false,
    loading,
    ...rest
}: Props) => {
    const { isSubmitting } = useFormState()
    const { t } = useTranslate()

    let isDisabled = isSubmitting || disabled || loading

    if (overrideDisable) {
        isDisabled = disabled
    }
    return (
        <PrimaryButton
            loading={isSubmitting || loading}
            disabled={isDisabled}
            size={btnSize ? btnSize : "lrg"}
            width={btnFull ? "full" : ""}
            hasicon={hasicon ? true : false}
            icontype={icontype}
            haslabel
            {...rest}
        >
            {displayText != null ? t.common(displayText) : ""}

            {children}
        </PrimaryButton>
    )
}
