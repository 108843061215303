import {
    ApiResponseModel,
    IPagedCollection,
    IRequestOptions,
    LookupEntry,
    ProductBase,
    ProductBaseFilter,
    ProductDeal,
    ProductFull,
} from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"
import commonService from "../common-services/commonService"

class ProductsService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "ProductsService"
    }

    async find(filter: ProductBaseFilter, options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<ProductBase>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductBase>>>("/products", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async findByQuery(
        query: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ProductFull>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductFull>>>("/products", {
            params: { query },
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async get(id: string, options?: IRequestOptions): Promise<ApiResponseModel<ProductBase>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductBase>>(`/products/${id}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getProductDeals(id: string, options?: IRequestOptions): Promise<ApiResponseModel<ProductDeal[]>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductDeal[]>>(`/products/${id}/deals`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getFullProductInfo(id: string, options?: IRequestOptions): Promise<ApiResponseModel<ProductFull>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductFull>>(`/products/${id}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async findFullProducts(
        filter?: ProductBaseFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ProductFull>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductFull>>>(`/products`, {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async downloadProductsReport(filter: ProductBaseFilter, type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(
            `/products/report/${type.id}`,
            type,
            filter,
            options)
    }
}

export default new ProductsService()
