import React from "react"

import { OptionRootElement, OptionRootElementProps } from "./OptionRootElement"
import { OptionElementProps } from "./OptionElement"

export interface DropdownOptionCategoryProps {
    name: string
    id: string
    searchQuery: string
    elements: OptionRootElementProps[]
    onUpdate: (id: string, isChecked: boolean) => void
}

export const DropdownOptionCategory: React.FC<DropdownOptionCategoryProps> = (props) => {
    const filterElements = (elements: OptionElementProps[]) => {
        if (props.searchQuery == null || props.searchQuery === "") {
            return elements
        }

        return elements.filter((i) => i.name.toLowerCase().includes(props.searchQuery.toLowerCase()))
    }

    return (
        <div className={`u-mt--sml--4 isOpen`} key={props.id}>
            <p className="c-filters__dropdown__title">{props.name}</p>
            <hr className="c-separator--color-light u-mt--sml--2" />
            {props.elements.map((el) => {
                const filteredElements = filterElements(el.elements)

                const forceExpand = props.searchQuery !== ""

                return filteredElements !== undefined && filteredElements !== null && filteredElements.length > 0 ? (
                    <div className="u-mt--sml--3 c-input--select c-input--select--sml isOpen" key={el.id}>
                        <OptionRootElement
                            id={el.id}
                            name={el.name}
                            elements={filteredElements}
                            forceExpand={forceExpand}
                            onUpdate={(id, value) => props.onUpdate(id, value)}
                        />
                    </div>
                ) : null
            })}
        </div>
    )
}
