import React, { useMemo } from "react"
import clsx from "clsx"

import { Breadcrumbs, BreadcrumbsRoute } from "@dnr/features/shared/breadcrumbs"
import { useOutletContext } from "react-router"
import { NavigationStore } from "@dnr/ui/navigation"
import { observer } from "mobx-react"
import { RightAsideStore } from "./store"

export type LayoutProps = {
    header?: React.ReactNode
    innerHeader?: React.ReactNode
    leftAside?: React.ReactNode
    rightAside?: React.ReactNode
    footer?: React.ReactNode
    breadcrumbs?: BreadcrumbsRoute[]
    // content?: React.ReactNode
    children: React.ReactNode
    rightAsideSize?: "xsml"
}

export const Layout: React.FC<LayoutProps> = observer((props) => {
    const { header, breadcrumbs } = props
    const menuStore = useOutletContext<NavigationStore>()
    const asideStore = useMemo(() => new RightAsideStore(), [])

    return (
        <div className={clsx(`l-container`)}>
            {header != null ? (
                <>
                    {/* HEADER */}
                    {header}
                </>
            ) : null}

            <main className={clsx(`l-wrapper`)}>
                {props.leftAside != null ? (
                    <div className="l-left" style={{ backgroundColor: "green" }}>
                        {/* LEFT ASIDE */}
                        {props.leftAside}
                    </div>
                ) : null}

                <div className="l-content">
                    <div
                        className={clsx(
                            asideStore.asideIsOpen ? "c-container--sml" : "c-container--base",
                            menuStore.isCollapsed ? "isCollapsed" : ""
                        )}
                    >
                        {props.innerHeader != null ? <div className="l-breadcrumbs">{props.innerHeader}</div> : null}

                        {props.children}
                    </div>
                </div>

                {props.rightAside != null ? (
                    <div className="l-right" style={{ backgroundColor: "red" }}>
                        {/* RIGHT ASIDE */}
                        {props.rightAside}
                    </div>
                ) : null}
            </main>

            {props.footer != null ? (
                <div className="l-footer" style={{ backgroundColor: "blue" }}>
                    {/* FOOTER */}
                    {props.footer}
                </div>
            ) : null}
        </div>
    )
})
