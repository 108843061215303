import { useTranslate } from "@dnr/localization"
import { number } from "yup/lib/locale"

export interface PriceCardProps {
    label: string
    value?: string | number
    note?: string
    direction?: string
    break?: boolean
}

export function PriceCard(props: PriceCardProps) {
    const { t } = useTranslate()
    return (
        <div
            className={`${
                props.direction == "column"
                    ? "u-display--flex u-display--flex--fd--column u-display--flex--gap--xsml"
                    : "u-display--separated"
            }`}
        >
            <span className="u-type--xsml u-type--color--text">{t.common(props.label)}</span>
            <span className={`u-type--accent ${props.break ? "u-type--break" : ""}`}>
                {props.value}
                {/* Per item or Per deal tag -- not needed for products */}
                {props.note != null ? <div> {t.common(props.note)} </div> : null}
            </span>
        </div>
    )
}

export default PriceCard
