import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react"

import { FieldValues, Path } from "react-hook-form"

import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    placeholder?: string
    name: Path<TForm>
    selectedValue?: string
    onUpdate: (value: FormDropdownOption) => void
    disabled?: boolean
}

export const TopicPriorityFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const topicPriorityId = lookupService.getTopicPriorityId()
    const topicPriority = lookupService.topicPriorityIds
    const { t } = useTranslate()

    const topicPrioritiesOptions = useMemo(() => {
        const lookupEntries = [] as FormDropdownOption[]
        for (const key in topicPriorityId) {
            lookupEntries.push({
                displayText: topicPriorityId[key].display,
                key: topicPriorityId[key].id.toString(),
                value: topicPriorityId[key].id.toString(),
                icon: topicPriorityId[key].icon,
                label: topicPriorityId[key].display,
            })
        }
        return lookupEntries
    }, [])

    const getDefaultValue = useMemo(() => {
        const filteredOptions = topicPrioritiesOptions.filter((i) => i.key === topicPriority["medium"].id.toString())
        return filteredOptions[0]
    }, [topicPrioritiesOptions])

    return (
        <FormDropdown<TForm>
            key={props.name}
            disabled={props.disabled}
            label={t.common("PRODUCT.PRIORITY")}
            options={topicPrioritiesOptions}
            color="light"
            selectedValue={props.selectedValue || getDefaultValue.key}
            {...props}
            type="default"
            placeholder={props.placeholder}
            required={props.required}
        />
    )
}
