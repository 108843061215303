import { forwardRef } from "react"

import { SnackbarContent, CustomContentProps } from "notistack"
import { OutlineButton } from "@dnr/ui/buttons"
import { PrimaryLink } from "@dnr/ui/links"

type Props = {
    details: string
    hideIcon?: boolean
    callback?: () => void
} & CustomContentProps

export const DetailedErrorNotification = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <SnackbarContent
            ref={ref}
            style={props.style}
            className="c-snackbar__container c-snackbar__container--error u-display--flex u-display--flex--fd--column"
        >
            <div className="notistack-snackbar">
                <div className="u-display--flex u-display--flex--gap--sml">
                    {" "}
                    {!props.hideIcon ? (
                        <svg
                            viewBox="0 0 24 24"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                fontSize: "20px",
                                width: "1em",
                                height: "1em",
                                display: "inline-block",
                                fill: "#fff",
                                flexShrink: "0",
                                marginTop: "1px",
                            }}
                        >
                            <path
                                d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,
        6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,
        13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
                            ></path>
                        </svg>
                    ) : null}
                    <div>
                        <h3 className="c-snackbar__title c-snackbar--white">{props.message}</h3>
                        <div className="c-snackbar--white u-mt--sml--1">{props.details}</div>

                        {props.callback ? (
                            <div className="u-display--flex u-display--flex--gap--xsml u-type--color--negative">
                                <div onClick={props.callback} className="c-anchor c-anchor--white u-width--unset">
                                    Click here
                                </div>
                                to see more.
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </SnackbarContent>
    )
})
