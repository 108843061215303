import { useTranslate } from "@dnr/localization"

/* eslint-disable-next-line */
export interface FeatureCardProps {
    label: string
    value: string
    type?: string
}

export function FeatureCard(props: FeatureCardProps) {
    const { t } = useTranslate()
    return (
        <div className="col col-lrg-4">
            <div className="c-features__feature__title"> {t.common(props.label)} </div>
            <div className="c-features__feature__value"> {props.value}</div>
        </div>
    )
}

export default FeatureCard
