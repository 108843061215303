import { useEffect, useState } from "react"

import { lookupService, warehouseService } from "@dnr/data/services"
import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"

interface PwhDropdownProps {
    allWarehouses?: boolean
    selectedValue?: string
    onChange: (option: DropdownOption) => void
    placeholder?: string
    label?: string
    error?: string
    type?: string
    direction?: string
    required?: boolean
    color?: string
    onReset?: () => void
    isFilter?: boolean
}

export const PwhDropdown = (props: PwhDropdownProps) => {
    const { t } = useTranslate()

    const [error, setError] = useState<string | undefined>(props.error)
    const [selectedDisplayText, setSelectedDisplayText] = useState<string>(
        props.placeholder || t.form("WAREHOUSE_MANAGEMENT.SHIPPING_WAREHOUSE_PLACEHOLDER")
    )

    useEffect(() => {
        setError(props.error)
        if (props.placeholder) {
            setSelectedDisplayText(props.placeholder!)
        }
    }, [props.error, props.placeholder])

    const { response: warehouses } = useRequest(async (options) => {
        const resp = await warehouseService.getAllCached(
            {
                warehouseTypeId: props.allWarehouses
                    ? undefined
                    : lookupService.warehouseTypeByAbrv["processingWarehouse"].id,
                pageNumber: 1,
                pageSize: 100,
            },
            {
                abortController: options.abortController,
            }
        )
        return resp
    }, [])

    return (
        <>
            <Dropdown
                selectedValue={props.selectedValue}
                type={props.type}
                direction={props.direction}
                iserror={error ? true : false}
                label={props.required ? `${props.label} *` : props.label}
                id="pwhDropdown"
                placeholder={selectedDisplayText}
                onChange={(option) => {
                    setError(undefined)
                    props.onChange(option)
                }}
                options={warehouses?.map((warehouse) => {
                    return {
                        key: warehouse.id,
                        value: warehouse.name,
                        displayText: warehouse.name,
                    } as DropdownOption
                })}
                color={props.color}
                resetFilter={props.onReset}
                isFilter={props.isFilter}
            />
            {error ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">{error}</span>
                </div>
            ) : null}
        </>
    )
}
