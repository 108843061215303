import { forwardRef, useCallback, useState } from "react"

import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack"

type Props = {
    details: string
    hideIcon?: boolean
    callback?: () => void
} & CustomContentProps

export const DetailedInfoNotification = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <SnackbarContent
            ref={ref}
            style={props.style}
            className="c-snackbar__container c-snackbar__container--info u-display--flex u-display--flex--fd--column"
        >
            <div className="notistack-snackbar">
                <div className="u-display--flex u-display--flex--gap--sml">
                    {" "}
                    {!props.hideIcon ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                            width={"16"}
                            height={"16"}
                            className="u-mt--sml--05"
                        >
                            <g id="information-circle--information-frame-info-more-help-point-circle">
                                <path
                                    id="Subtract"
                                    fill="#fff"
                                    fill-rule="evenodd"
                                    d="M7 14c3.866 0 7-3.134 7-7 0-3.86599-3.134-7-7-7-3.86599 0-7 3.13401-7 7 0 3.866 3.13401 7 7 7ZM5.5 9.375c-.34518 0-.625.27982-.625.625 0 .3452.27982.625.625.625h3c.34518 0 .625-.2798.625-.625 0-.34518-.27982-.625-.625-.625h-.875V6.5c0-.34518-.27982-.625-.625-.625H6c-.34518 0-.625.27982-.625.625s.27982.625.625.625h.375v2.25H5.5ZM8 4c0 .55228-.44772 1-1 1s-1-.44772-1-1 .44772-1 1-1 1 .44772 1 1Z"
                                    clip-rule="evenodd"
                                ></path>
                            </g>
                        </svg>
                    ) : null}
                    <div>
                        <h3 className="c-snackbar__title c-snackbar--white">{props.message}</h3>
                        <div className="u-mt--sml--1 c-snackbar--white">{props.details}</div>

                        {props.callback ? (
                            <div className="u-display--flex u-display--flex--gap--xsml u-type--color--negative">
                                <div onClick={props.callback} className="c-anchor c-anchor--white u-width--unset">
                                    Click here
                                </div>
                                to see more.
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </SnackbarContent>
    )
})
