import clsx from "clsx"
import React from "react"

type Props = {
    size?: "sml" | "med" | "lrg"
    className?: string
    children: React.ReactNode
}

export const BaseCard: React.FC<Props> = (props) => (
    <div className={clsx("c-card", props.size ? props.size : "med", props.className)}>{props.children}</div>
)
