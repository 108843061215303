import { useState } from "react"

import { useForm } from "react-hook-form"
import { observer } from "mobx-react"

import { rootModalStore } from "@dnr/state/modal"
import { ButtonGroup, SecondaryButton } from "@dnr/ui/buttons"
import { FormContainer, FormDropdownOption, FormSubmit } from "@dnr/ui/forms"
import { useTranslate } from "@dnr/localization"
import { UserDropdownOption, UserSearchDropdown } from "@dnr/features/shared/dropdowns"
import { lookupService } from "@dnr/data/services"

import { TopicPriorityFormDropdown } from "../dropdowns/form-dropdowns/TopicPriorityFormDropdown"
import { TopicStatusFormDropdown } from "../dropdowns/form-dropdowns/TopicStatusFormDropdown"
import { Topic } from "../../models"
import { TopicCategoryFormDropdown, TopicTypeFormDropdown } from ".."

export interface TicketEditForm {
    typeId: number
    categoryId: number
    priorityId: number
    statusId: number
    assigneeUserId?: string
    clearAssignee?: boolean
}

interface ModalData extends Topic {
    isMessages: boolean
}

export const TicketEdit = observer(() => {
    const store = rootModalStore.modalStore
    const ticket = store.modalData as ModalData
    const { t } = useTranslate()

    const isStatusNew = () => {
        return lookupService.topicStatusIds["new"].id === ticket.statusId
    }

    const ticketPriorityById = (id: number) => {
        return lookupService.getTopicPriorityId()[id].display
    }

    const ticketCategoryById = (id: number) => {
        return lookupService.getTopicCategoryId()[id].display
    }

    const ticketTypeById = (id: number) => {
        return lookupService.getTopicTypeId()[id].display
    }

    const ticketStatusById = (id: number) => {
        return lookupService.getTopicStatusId()[id].display
    }

    const form = useForm<TicketEditForm>()

    const [assignedUserDisplay, setAssignedUserDisplay] = useState<string | undefined>(
        ticket.assigneeUser ? `${ticket.assigneeUser?.firstName} ${ticket.assigneeUser?.lastName}` : undefined
    )

    const onSubmit = async (data: TicketEditForm) => {
        if (store.callback) store.callback(data)
        store.closeModal()
    }

    const topicTypeHandler = (value: FormDropdownOption) => {
        form.setValue("typeId", Number(value.key))
        form.trigger("typeId")
    }

    const topicCategoryHandler = (value: FormDropdownOption) => {
        form.setValue("categoryId", Number(value.key))
    }

    const topicPriorityHandler = (value: FormDropdownOption) => {
        form.setValue("priorityId", Number(value.key))
    }

    const topicStatusHandler = (value: FormDropdownOption) => {
        form.setValue("statusId", Number(value.key))
    }

    const topicAssigneeHandler = (value: UserDropdownOption) => {
        form.setValue("assigneeUserId", value.userId)
        setAssignedUserDisplay(value.displayText)
        form.setValue("clearAssignee", undefined)
    }

    const unassignHandler = () => {
        setAssignedUserDisplay(t.common("TICKETS.UNASSIGNED"))
        form.setValue("assigneeUserId", undefined)
        form.setValue("clearAssignee", true)
    }

    return (
        <FormContainer onSubmit={onSubmit} form={form}>
            <div className="u-mb--sml--3">
                <TopicPriorityFormDropdown<TicketEditForm>
                    selectedValue={ticket.priorityId.toString()}
                    name="priorityId"
                    placeholder={ticketPriorityById(ticket.priorityId) || t.common("TICKETS.SELECT_PRIORITY")}
                    onUpdate={(value) => topicPriorityHandler(value)}
                />
            </div>
            <div className="u-mb--sml--3">
                <TopicStatusFormDropdown<TicketEditForm>
                    selectedValue={ticket.statusId.toString()}
                    isStatusNew={isStatusNew()}
                    name="statusId"
                    placeholder={ticketStatusById(ticket.statusId) || t.common("TICKETS.SELECT_STATUS")}
                    onUpdate={(value) => topicStatusHandler(value)}
                />
            </div>

            <TopicTypeFormDropdown<TicketEditForm>
                name="typeId"
                selectedValue={ticket.typeId.toString()}
                placeholder={ticketTypeById(ticket.typeId) || t.common("TICKETS.SELECT_TOPIC_TYPE")}
                onUpdate={(value) => topicTypeHandler(value)}
            />
            <TopicCategoryFormDropdown<TicketEditForm>
                name="categoryId"
                selectedValue={ticket.categoryId.toString()}
                placeholder={ticketCategoryById(ticket.categoryId) || t.common("TICKETS.SELECT_TOPIC_CATEGORY")}
                onUpdate={(value) => topicCategoryHandler(value)}
            />

            <div className="u-mb--sml--3">
                <UserSearchDropdown
                    label="Assignee"
                    size="base"
                    placeholder={assignedUserDisplay || t.common("GENERAL.SELECT_USER")}
                    searchRole="backofficeAdmin"
                    onChange={(value) => {
                        topicAssigneeHandler(value)
                    }}
                    color="light"
                />
            </div>

            {ticketStatusById(ticket.statusId) !== "New" ||
            !ticket.assigneeUserId ||
            form.getValues("clearAssignee") ? null : (
                <button className="c-btn c-btn--sml c-btn--basic" onClick={unassignHandler}>
                    {t.common("TICKETS.UNASSIGN")}
                </button>
            )}

            <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" />
            <ButtonGroup style="separated">
                <SecondaryButton size="base" type="button" onClick={() => store.closeModal()}>
                    {store.closingText}
                </SecondaryButton>
                <FormSubmit displayText={store.confirmText} btnSize="base" />
            </ButtonGroup>
        </FormContainer>
    )
})
