import { DateTime } from "luxon"

import { ApiResponseModel, Feature, IFilter, IPagedCollection, IRequestOptions } from "@dnr/data/models"
import { localStorageProvider } from "@dnr/util/storage"
import { ServiceBase } from "../common-services/ServiceBase"

export interface FeatureCreate {
    name: string
    displayName?: string
    position: number
}

interface FeaturesCacheEntry {
    createdAt: Date
    entries: ApiResponseModel<IPagedCollection<Feature>>
}

class FeaturesService extends ServiceBase {
    getLoggerName(): string {
        return "FeaturesService"
    }

    async find(filter: IFilter, options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<Feature>>> {
        const resp = await this.http.instance.get("/products/features/", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return resp.data
    }

    async getAllCached(options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<Feature>>> {
        const cachedValues = localStorageProvider.get<FeaturesCacheEntry>("features-cache")

        if (
            cachedValues?.value === undefined ||
            cachedValues.value === null ||
            cachedValues.value.createdAt < DateTime.now().minus({ minutes: 10 }).toJSDate()
        ) {
            const resp = await this.http.instance.get<ApiResponseModel<IPagedCollection<Feature>>>(
                "/products/features/",
                {
                    params: {
                        pageNumber: 1,
                        pageSize: 1000,
                    },
                    signal: options?.abortController.signal,
                }
            )

            localStorageProvider.set("features-cache", {
                createdAt: DateTime.now().toJSDate(),
                entries: resp.data,
            })

            return resp.data
        }
        return cachedValues.value.entries
    }

    async put(featureId: string, updatedFeature: FeatureCreate): Promise<Feature> {
        const response = await this.http.instance.put<Feature>(`/products/features/${featureId}`, updatedFeature)
        return response.data
    }

    async post(feature: FeatureCreate): Promise<ApiResponseModel<Feature>> {
        const response = await this.http.instance.post<ApiResponseModel<Feature>>("/products/features/", feature)
        return response.data
    }

    async delete(featureIds: string[]): Promise<void> {
        const response = await this.http.instance.delete("/products/features/", {
            params: {
                featureIds,
            },
        })
        return response.data
    }
}
export default new FeaturesService()
