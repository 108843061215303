import { useState } from "react"

import { terminalService } from "@dnr/data/services"
import { useRequest } from "@dnr/hooks"
import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"

interface ReceivingTerminalDropdownProps {
    warehouseId: string
    default?: string
    label?: string
    reset?: number | string
    placeholder?: string
    selectedValue?: string
    inputSize?: string
    onChange: (selected: DropdownOption) => void
    size?: string
    env?: "portal" | "backoffice"
    disabled?: boolean
    disabledMessage?: string
    isFilter?: boolean
    onReset?: () => void
}

export const ReceivingTerminalDropdown = (props: ReceivingTerminalDropdownProps) => {
    const [terminals, setTerminals] = useState<DropdownOption[]>([])

    useRequest(
        async (options) => {
            const { result } = await terminalService.find(
                { warehouseIds: [props.warehouseId], pageNumber: 1, pageSize: 1000 },
                {
                    abortController: options.abortController,
                }
            )

            const dropdownOptions = result.items.map((wh) => {
                return {
                    key: wh.id.toString(),
                    value: wh.name,
                    displayText: wh.name,
                } as DropdownOption
            })

            setTerminals(dropdownOptions)
        },
        [props.warehouseId]
    )

    return (
        <Dropdown
            selectedValue={props.default}
            resetStates={props.reset}
            id="terminal-dropdown"
            label={props.label}
            type="select"
            placeholder={props.placeholder}
            options={terminals}
            onChange={(option) => props.onChange(option)}
            size={props.size}
            env={props.env}
            disabled={props.disabled}
            disabledMessage={props.disabledMessage}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
            color="light"
        />
    )
}
