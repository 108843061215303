import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react"

import { FieldValues, Path } from "react-hook-form"

import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    placeholder?: string
    name: Path<TForm>
    selectedValue?: string
    onUpdate: (value: FormDropdownOption) => void
    disabled?: boolean
    env?: "portal" | "backoffice"
}

export const TopicTypeFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const topicTypes = lookupService.getTopicTypeId()
    const topicTypeId = lookupService.topicTypeIds
    const { t } = useTranslate()

    const topicTypeOptions = useMemo(() => {
        const lookupEntries = [] as FormDropdownOption[]
        for (const key in topicTypes) {
            if (topicTypes[key].abrv !== "shipping-request") {
                lookupEntries.push({
                    displayText: topicTypes[key].display,
                    key: topicTypes[key].id.toString(),
                    value: topicTypes[key].id.toString(),
                    icon: topicTypes[key].icon,
                    label: topicTypes[key].display,
                })
            }
        }
        return lookupEntries
    }, [])

    const getDefaultValue = useMemo(() => {
        const filteredOptions = topicTypeOptions.filter(
            (i) => i.key === topicTypeId[props.env === "portal" ? "problem" : "notification"].id.toString()
        )
        return filteredOptions[0]
    }, [topicTypeOptions])

    return (
        <FormDropdown<TForm>
            key={props.name}
            disabled={props.disabled}
            label={t.common("TRANSACTIONS.TYPE")}
            options={topicTypeOptions}
            color="light"
            selectedValue={props.selectedValue || getDefaultValue.key}
            {...props}
            placeholder={props.placeholder || getDefaultValue.displayText}
            required={props.required}
        />
    )
}
