import React from "react"
import { useNavigate } from "react-router-dom"

import clsx from "clsx"

import { AccentButton, PrimaryButton, SecondaryButton } from "@dnr/ui/buttons"
import { useTranslate } from "@dnr/localization"
import { helperService } from "@dnr/data/services"

export type BaseEmptyStateProps = {
    type: "primary" | "secondary" | "tertiary"
    size?: string
    category?:
        | "commitments"
        | "activity"
        | "trackings"
        | "balance"
        | "transactions"
        | "mydeals"
        | "deals"
        | "products"
        | "product-deals"
        | "deal-commitments"
        | "productsSecond"
        | "payment"
    user?: string
    admin?: boolean
    products?: number
    blankAction?: boolean
}

export const BaseEmptyState: React.FC<BaseEmptyStateProps> = (props) => {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { replacePlaceholder } = helperService

    const getTitle = () => {
        switch (props.category) {
            case "commitments":
                return t.common("EMPTY_STATES.COMMITMENTS.TITLE")
            case "deals":
                return t.common("EMPTY_STATES.DEALS.TITLE")
            case "activity":
                return t.common("EMPTY_STATES.ACTIVITY.TITLE")
            case "trackings":
                return t.common("EMPTY_STATES.TRACKINGS.TITLE")
            case "balance":
                return t.common("EMPTY_STATES.BALANCE.TITLE")
            case "transactions":
                return t.common("EMPTY_STATES.TRANSACTIONS.TITLE")
            case "mydeals":
                return "No deals yet!"
            case "products":
                return t.common("EMPTY_STATES.PRODUCTS.TITLE")
            case "payment":
                return t.common("EMPTY_STATES.PAYMENT.TITLE")
            case "productsSecond":
                return "Loos like there are no products to ship!"
            default:
                return ""
        }
    }

    const getBody = () => {
        switch (props.category) {
            case "commitments":
                return t.common("EMPTY_STATES.COMMITMENTS.BODY")
            case "deals":
                return t.common("EMPTY_STATES.DEALS.BODY")
            case "activity":
                return t.common("EMPTY_STATES.ACTIVITY.BODY")
            case "trackings":
                return t.common("EMPTY_STATES.TRACKINGS.BODY")
            case "balance":
                return t.common("EMPTY_STATES.BALANCE.BODY")
            case "transactions":
                return t.common("EMPTY_STATES.TRANSACTIONS.BODY")
            case "mydeals":
                return "No deals yet!"
            case "products":
                return t.common("EMPTY_STATES.PRODUCTS.BODY")
            case "payment":
                return t.common("EMPTY_STATES.PAYMENT.BODY")
            case "productsSecond":
                return "Loos like there are no products to ship!"
            default:
                return ""
        }
    }

    const displayButton = () => {
        if (props.blankAction) return false
        else if (props.type === "primary" && props.category != "productsSecond") return true
        else return false
    }

    return (
        <div className={clsx(`c-empty`, `c-empty--${props.type}`)}>
            <img
                src={clsx(
                    `../../../../assets/img/empty-states/${
                        props.category == "products" ||
                        props.category == "deal-commitments" ||
                        props.category == "productsSecond" ||
                        props.category == "payment"
                            ? "commitments"
                            : props.category === "product-deals"
                            ? "deals"
                            : props.category
                    }.png`
                )}
                className={`u-mb--sml--2 c-empty--${props.size}`}
            />
            <div className={`${displayButton() ? "u-mb--sml--6" : null}`}>
                {props.admin !== true ? (
                    <p className="c-empty__title">{getTitle()}</p>
                ) : (
                    <p className="c-empty__title">Oops!</p>
                )}

                {props.admin !== true ? (
                    <p className="c-empty__text">{getBody()}</p>
                ) : (
                    <p className="c-empty__text">
                        {props.category === "product-deals"
                            ? t.common("EMPTY_STATES.PRODUCT_DEALS.TITLE")
                            : props.category === "deal-commitments"
                            ? t.common("EMPTY_STATES.DEAL_COMMITMENTS.TITLE")
                            : props.category === "payment"
                            ? t.common("EMPTY_STATES.PAYMENT.TITLE")
                            : props.category === "productsSecond"
                            ? t.common("EMPTY_STATES.SHIPPING_REQUEST_NO_PRODUCTS")
                            : replacePlaceholder(t.common("EMPTY_STATES.NO_CATEGORY_YET"), props.category || "")}
                    </p>
                )}
            </div>

            {displayButton() ? (
                <PrimaryButton
                    onClick={() =>
                        navigate(
                            props.category === "mydeals" ? "/app/products/list?page=1&rpp=36" : "/app/deals/list/all"
                        )
                    }
                    hasicon
                    haslabel
                    size="base"
                >
                    {props.category === "mydeals"
                        ? t.common("EMPTY_STATES.SEE_ALL_PRODUCTS")
                        : t.common("EMPTY_STATES.EXPLORE_ALL_DEALS")}
                </PrimaryButton>
            ) : null}

            {props.type === "secondary" ? (
                <AccentButton onClick={() => navigate("/app/deals/list/all")} hasicon haslabel size="base">
                    {t.common("EMPTY_STATES.EXPLORE_ALL_DEALS")}
                </AccentButton>
            ) : (
                ""
            )}
            {props.type === "tertiary" ? (
                <div className="u-display--flex">
                    <div className="u-mr--sml--2">
                        <PrimaryButton onClick={() => navigate("/app/deals/list/all")} hasicon haslabel size="base">
                            {t.common("EMPTY_STATES.EXPLORE_ALL_DEALS")}
                        </PrimaryButton>
                    </div>
                    <SecondaryButton onClick={() => navigate("/app/commitments/list/all")} haslabel size="base">
                        {t.common("EMPTY_STATES.SEE_COMMITMENTS")}
                    </SecondaryButton>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}
