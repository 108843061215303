import { DetailedHTMLProps, InputHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useTranslate } from "@dnr/localization"
import { PrimaryButton } from "@dnr/ui/buttons"
import { DeepRequired, FieldErrorsImpl, FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"
import { helperService } from "@dnr/data/services"

export type FormFileInputProps<TForm extends FieldValues> = {
    color?: string
    name: Path<TForm>
    options?: RegisterOptions<TForm>
    onFileSelected: (file: File) => void
    type?: string
    clearFileName?: boolean
    warningMsg?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const FormFileInput = <TForm extends FieldValues>(props: FormFileInputProps<TForm>) => {
    const { t } = useTranslate()
    const { handleDrag, handleDrop } = helperService

    const form = useFormContext<TForm>()

    const nameSplitted = useMemo(() => {
        return props.name.split(".")
    }, [props.name])

    const [fileName, setFileName] = useState<string>()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setFileName("")
    }, [props.clearFileName])

    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            props.onFileSelected(e.target.files[0])
            form.clearErrors()
            setFileName(e.target.files[0].name)
        }
    }

    const getErrorMessage = useCallback((errors: FieldErrorsImpl<DeepRequired<TForm>>) => {
        if (Object.keys(errors).length === 0) {
            return null
        }

        if (errors[props.name]?.message !== undefined) {
            return translateErrorMessage(errors[props.name]?.message?.toString())
        }
        // In case the error is in the array
        else if (nameSplitted.length > 1) {
            let finalErrorMessage
            let currentObject = undefined as any
            nameSplitted.every((nameSection) => {
                currentObject = currentObject === undefined ? errors[nameSection] : currentObject[nameSection]
                if (currentObject?.message !== undefined) {
                    finalErrorMessage = translateErrorMessage(currentObject?.message.toString())
                    return false
                }
                return true
            })

            if (finalErrorMessage !== undefined) {
                return finalErrorMessage
            }
        }

        return null
    }, [])

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }

        return err
    }

    return (
        <>
            <div className="u-mb--sml--1">
                <label className="c-input__label">
                    {props.type == "image" ? t.common("GENERAL.UPLOAD_PRODUCT_IMAGE") : t.common("GENERAL.FILE")}{" "}
                    {props.required ? <span className="u-type--color--error">*</span> : null}
                </label>
            </div>

            {getErrorMessage(form.formState.errors) !== null ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">{getErrorMessage(form.formState.errors)}</span>
                </div>
            ) : null}

            {props.warningMsg ? (
                <div className="c-error--warning c-error--xsml u-mb--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__warning__message">{props.warningMsg}</span>
                </div>
            ) : null}

            <div
                className={`c-input--file ${getErrorMessage(form.formState.errors) ? "c-input--error" : ""}`}
                id="form-file-upload"
            >
                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) =>
                        handleDrop(e, (files) => {
                            props.onFileSelected(files[0])
                            setFileName(files[0].name)
                        })
                    }
                    id="drag-file-element"
                    className="c-input--file--drag-area"
                >
                    <p className="u-type--base u-type--color--title u-type--wgt--semibold">
                        {t.common("GENERAL.DRAG_DROP")}
                    </p>
                    <p className="u-type--sml u-type--color--light u-type--wgt--regular">or</p>
                    <PrimaryButton
                        onClick={() => document.getElementById("input-file-upload")?.click()}
                        size="sml"
                        haslabel
                        id="file-button"
                        type="button"
                    >
                        {t.common("GENERAL.BROWSE")}
                    </PrimaryButton>
                    <input
                        ref={inputRef}
                        type="file"
                        id="input-file-upload"
                        multiple={true}
                        onChange={handleChange}
                        hidden
                    />
                    {/* UPLOADED FILE NAME DISPLAY */}
                    <p className="u-type--base u-type--color--light u-type--wgt--regular">{fileName}</p>
                </div>
            </div>
        </>
    )
}
