import { createPortal } from "react-dom"

import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover"
import React, { useState } from "react"
import clsx from "clsx"

interface TableDropdownProps {
    list: any[]
    actionTrigger: React.ReactNode
    itemKey: string
    itemAbrv: string
    triggerClassName: string
    disabled?: boolean
    width?: string
    height?: string
    extendedClassName?: string
    additionalOptions?: any[]
    id?: string
}

export const TableDropdown = (props: TableDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isButtonOpen, setIsButtonOpen] = useState(false)

    if (!props.list) return null

    const id = props.id

    const handleClose = (item: any) => {
        item.action()
        setIsOpen(false)
    }

    const handleIsButtonOpen = () => {
        setIsButtonOpen(!isButtonOpen)
    }

    const handleSelectButtonOption = (item: any) => {
        item.action(id)
        setIsButtonOpen(false)
    }

    return (
        <Popover
            open={isOpen}
            onOpenChange={() => {
                if (!props.disabled) setIsOpen(!isOpen)
            }}
        >
            <PopoverTrigger className={clsx(props.triggerClassName, props.width == "full" ? "c-btn--full" : null)}>
                {props.actionTrigger}
            </PopoverTrigger>

            {!props.disabled
                ? createPortal(
                      <PopoverContent align="end" hideWhenDetached className="content">
                          <div
                              className={`c-dropdown__actions ${
                                  props.height ? `c-dropdown__actions--${props.height}` : "c-dropdown__actions--sml "
                              } ${props.extendedClassName ? `${props.extendedClassName}` : null}`}
                          >
                              {props.list?.map((item: any) => {
                                  if (item[props.itemAbrv] == "pauseDeal") {
                                      return (
                                          <>
                                              <hr className="c-separator--color-light u-mt--sml--0 u-mb--sml--0" />
                                              <div
                                                  key={Math.random()}
                                                  className={clsx(
                                                      "c-dropdown__action",
                                                      !item.enabled ? "c-dropdown__action--disabled" : null
                                                  )}
                                                  onClick={() => {
                                                      if (item.enabled) {
                                                          handleClose(item)
                                                      }
                                                  }}
                                              >
                                                  {item[props.itemKey]}
                                              </div>
                                          </>
                                      )
                                  } else if (item[props.itemAbrv] == "getReport") {
                                      return (
                                          <>
                                              <hr className="c-separator--color-light u-mt--sml--0 u-mb--sml--0" />

                                              <div
                                                  className={clsx(isButtonOpen ? "isOpen" : "")}
                                                  onClick={() => {
                                                      if (!props.disabled && item.enabled) {
                                                          handleIsButtonOpen()
                                                      }
                                                  }}
                                              >
                                                  <div
                                                      className={clsx(
                                                          "c-dropdown__action",
                                                          "u-display--flex",
                                                          "u-display--flex--jc--sb",
                                                          !item.enabled ? "c-dropdown__action--disabled" : null
                                                      )}
                                                  >
                                                      {item[props.itemKey]}{" "}
                                                      <i className="u-icon u-icon--base u-icon--arrow-down" />
                                                  </div>

                                                  {isButtonOpen ? (
                                                      <div className="c-dropdown__list u-pb--sml--2">
                                                          {props.additionalOptions?.map((item) => {
                                                              return (
                                                                  <div
                                                                      className={clsx("c-dropdown__option")}
                                                                      key={item.key}
                                                                      onClick={() => handleSelectButtonOption(item)}
                                                                  >
                                                                      <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml">
                                                                          {item.icon ? (
                                                                              <i
                                                                                  className={`u-icon--stock-status u-icon u-icon--${item.icon}`}
                                                                              ></i>
                                                                          ) : null}
                                                                          <span>{item.displayText}</span>
                                                                      </div>
                                                                  </div>
                                                              )
                                                          })}
                                                      </div>
                                                  ) : null}
                                              </div>
                                          </>
                                      )
                                  } else
                                      return (
                                          <div
                                              key={Math.random()}
                                              className={clsx(
                                                  "c-dropdown__action",
                                                  !item.enabled ? "c-dropdown__action--disabled" : null
                                              )}
                                              onClick={() => {
                                                  if (item.enabled) {
                                                      handleClose(item)
                                                  }
                                              }}
                                          >
                                              {item[props.itemKey]}
                                          </div>
                                      )
                              })}
                          </div>
                      </PopoverContent>,
                      document.body
                  )
                : null}
        </Popover>
    )
}
