import { Link, useNavigate } from "react-router-dom"

import { Image } from "@dnr/ui/images"
import clsx from "clsx"
import { BaseTag, IconTag } from "@dnr/ui/tags"
import { useTranslate } from "@dnr/localization"

// This should be used for the special tags on top of an image
export interface ImageCardTags {
    title: string
}

/* eslint-disable-next-line */
export interface ImageCardProps {
    imageUrl?: string
    link: string
    linkText: string
    imageSize: "sml" | "base" | "med" | "mlrg" | "lrg" | "xlrg"
    size?: "sml" | "base" | "med" | "mlrg" | "lrg" | "xlrg"
    titleSize?: string
    tags?: []
    type?: string
    overlay?: boolean
    commission?: any
    extendedClassName?: string
    position?: string
    background?: boolean
    env?: "runner" | "dealer" | "usermgmt"
    titleTagType?: string
    isOutbid?: boolean
    isHot?: boolean
}

export function ImageCard(props: React.PropsWithChildren<ImageCardProps>) {
    const spacingClass = props.imageSize === "xlrg" || props.extendedClassName ? "" : "u-ml--sml--2"
    const { t } = useTranslate()

    return (
        <div
            className={clsx(
                "c-cards",
                props.type ? `c-cards--${props.type}` : "",
                props.size ? `c-cards--${props.size}` : "",
                props.position === "rel" ? "u-position--rel" : "",
                props.size === "sml" ? "c-cards--row" : "",
                props.extendedClassName ? props.extendedClassName : ""
            )}
        >
            <div
                className={clsx(
                    "c-cards__header",
                    props.imageSize === "xlrg" ? "c-cards__header--vertical" : "c-cards__header--horizontal",
                    props.size === "sml" ? (props.env == "usermgmt" ? "col-sml-6" : "col-sml-3") : ""
                )}
            >
                {props.env != "usermgmt" ? (
                    <div className={clsx(props.overlay ? "c-cards__image--overlay" : "")}>
                        <Image
                            src={props.imageUrl ?? ""}
                            className={`c-cards__image c-cards__image--${props.imageSize} `}
                        />
                    </div>
                ) : null}

                <div
                    className={clsx(
                        props.commission
                            ? "u-display--flex u-display--flex--fd--column u-display--flex--gap--xsml"
                            : null
                    )}
                >
                    {props.link ? (
                        <div className={`${props.env != "usermgmt" ? "u-display--width-fit" : null}`}>
                            {/* // ! - HOT TAG - latest products; Dashboard */}
                            {props.titleTagType == "hot" ? (
                                <div className="u-display--flex u-display--flex--gap--sml u-display--flex--ai--center">
                                    <IconTag
                                        tagtype="hot"
                                        iconType="hot"
                                        iconSize="sbase"
                                        tagtitle={t.common("DEAL.HOT")}
                                    />

                                    <div className="c-cards__link">
                                        <Link
                                            className={clsx(
                                                `c-cards__title--link`,
                                                props.titleSize ? `c-cards__title--link--${props.titleSize}` : null,
                                                spacingClass
                                            )}
                                            to={props.link}
                                        >
                                            {props.linkText}
                                        </Link>
                                        <span className="c-tooltip c-tooltip--lrg">{props.linkText}</span>
                                    </div>
                                </div>
                            ) :  (
                                <div className="c-cards__link">
                                    <Link
                                        className={clsx(
                                            `c-cards__title--link`,
                                            props.titleSize ? `c-cards__title--link--${props.titleSize}` : null,
                                            props.type == "mydeals-deal" ?  `c-cards__title--link--big` : `c-cards__title--link--${props.titleSize}`,
                                            props.imageSize == "mlrg" ? "u-ml--sml--2" : ""
                                        )}
                                        to={props.link}
                                    >
                                        {props.linkText}
                                    </Link>
                                    <span className="c-tooltip c-tooltip--lrg">{props.linkText}</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link
                            className={clsx(
                                "c-cards__title",
                                spacingClass,
                                props.imageSize === "sml" ? " c-cards__title--sml " : "",
                                props.size === "base" && props.type !== "dashboard-deal" ? "c-cards__title--base" : ""
                            )}
                            to={props.link}
                        >
                            {props.linkText}
                        </Link>
                    )}
                    {props.commission ? (
                        <div className={clsx("u-display--flex u-mt--sml--05", spacingClass)}>{props.commission}</div>
                    ) : null}
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default ImageCard
