import { ApiResponseBaseModel, ApiResponseModel, ILogin, ILoginResponse, ResetPasswordData } from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class LoginService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "LoginService"
    }

    async login(data: ILogin): Promise<ApiResponseModel<ILoginResponse>> {
        const res = await this.http.instance.post<ApiResponseModel<ILoginResponse>>("/account/login", data)
        return res.data
    }

    async logout(): Promise<ApiResponseBaseModel> {
        const res = await this.http.instance.post<ApiResponseBaseModel>("/account/logout")
        return res.data
    }

    async forgotPassword(email: string): Promise<void> {
        const res = await this.http.instance.post<void>("/account/emails/forgot-password", {
            email,
        })
        return res.data
    }

    async resetPassword(data: ResetPasswordData): Promise<void> {
        const res = await this.http.instance.put<void>("/account/reset-password", data)
        return res.data
    }
}
export default new LoginService()
