import clsx from "clsx"
import React from "react"

import { BaseButton, BaseButtonProps } from "./BaseButton"

export type TertiaryButtonProps = {} & Omit<BaseButtonProps, "className">

export const TertiaryButton: React.FC<TertiaryButtonProps> = (props) => (
    <BaseButton {...props} className={clsx("tertiary")} />
)
