import React, { useEffect, useState } from "react"

import { OptionElement, OptionElementProps } from "./OptionElement"

export interface OptionRootElementProps {
    id: string
    name: string
    elements: OptionElementProps[]
    forceExpand: boolean
    onUpdate: (id: string, isChecked: boolean) => void
}

export const OptionRootElement: React.FC<OptionRootElementProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(props.forceExpand)

    useEffect(() => {
        setIsExpanded(props.forceExpand)
    }, [props.forceExpand])

    return (
        <div className="c-filters__dropdown__option" key={props.id}>
            <label onClick={() => setIsExpanded(!isExpanded)}>
                <p className="c-filters__dropdown__select__title">{props.name}</p>
            </label>

            {isExpanded ? (
                <div className="c-filters__dropdown__select">
                    {props.elements.map((item) => {
                        return (
                            <OptionElement
                                id={item.id}
                                key={item.id}
                                name={item.name}
                                isChecked={item.isChecked}
                                onUpdate={(id, value) => props.onUpdate(id, value)}
                            />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}
