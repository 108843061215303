import { DependencyList, useCallback, useEffect, useRef, useState } from "react"

import { observer } from "mobx-react"
import clsx from "clsx"
import { closeSnackbar, enqueueSnackbar } from "notistack"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-material.css"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import {
    ColDef,
    ColGroupDef,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    MenuItemDef,
    SortModelItem,
} from "ag-grid-community"
import { LicenseManager } from "ag-grid-enterprise"

import { defaultLogger } from "@dnr/util/logger"
import { IFilter, IPagedCollection, LookupEntry } from "@dnr/data/models"
import { errorHandlingService, lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

import { TableStore } from "./TableStore"
import { MobxTablePager } from "./MobxTablePager"
import { MobxTableViewEmptyState } from "./MobxTableViewEmptyState"
import TableCellLoader from "./TableCellLoader"

LicenseManager.setLicenseKey(process.env["NX_AG_GRID_LICENSE"] ?? "")

/* eslint-disable-next-line */
export interface MobxTableViewProps<TData, TFilter extends IFilter> {
    store: TableStore<TFilter>
    externalFilters?: DependencyList
    gridOptions?: GridOptions<TData>
    detailRowHeight?: number
    columnDefinitions: (ColDef<TData> | ColGroupDef<TData>)[]
    apiCall: (filter: IFilter) => Promise<IPagedCollection<TData>>
    buildApiFilter?: (filter: any) => IFilter
    apiPropertyNameMappings?: Record<string, string>
    customExport?: (type: LookupEntry) => Promise<void>
    className?: string
    enableExport?: boolean
    isInnerTable?: boolean
}

export const MobxTableView = observer(function MobxTableView2<TData, TFilter extends IFilter>(
    props: MobxTableViewProps<TData, TFilter>
) {
    const gridRef = useRef<AgGridReact<TData>>(null)
    const { t } = useTranslate()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isEmptyState, setIsEmptyState] = useState<boolean>(true)
    const [tableRowLength, setTableRowLength] = useState<number>(0)
    const [_, setIsCreatingReport] = useState<boolean>(true)

    let itemsLen = 0

    const getOrderByParam = (sorts: SortModelItem[]) => {
        if (sorts === null || sorts === undefined || sorts.length < 1) {
            return null
        }

        const sortItems = [] as string[]
        sorts.forEach((sortItem) => {
            const columnName =
                props.apiPropertyNameMappings && props.apiPropertyNameMappings[sortItem.colId]
                    ? props.apiPropertyNameMappings[sortItem.colId]
                    : sortItem.colId
            sortItems.push(`${columnName}|${sortItem.sort}`)
        })

        props.store.setFilter({
            ...props.store.additionalFilter,
            orderBy: sortItems.join(","),
        })
        return sortItems.join(",")
    }

    useEffect(() => {
        if (gridRef.current?.api !== undefined && props.externalFilters && props.externalFilters.length > 0) {
            setIsLoading(true)
            onPageSizeChange(props.store.pagingStore.pageSize)
        }
    }, props.externalFilters)

    const getServerSideDatasource: () => IServerSideDatasource = () => {
        setIsLoading(true)
        return {
            getRows(params: IServerSideGetRowsParams<TData>): void {
                try {
                    const req = params.request as IServerSideGetRowsRequest

                    let pageNumber = props.store.pagingStore.currentPage
                    let pageSize = props.store.pagingStore.pageSize

                    setTableRowLength(itemsLen)

                    if (req.startRow !== undefined && req.endRow !== undefined) {
                        pageSize = req.endRow - req.startRow
                        pageNumber = req.startRow !== undefined && req.startRow > 1 ? req.startRow / pageSize + 1 : 1
                    }

                    const orderBy = getOrderByParam(req.sortModel) || props.store.additionalFilter.orderBy

                    const updatedFilter = props.buildApiFilter ? props.buildApiFilter(req.filterModel) : ({} as IFilter)

                    const finalFilter = {
                        ...props.store.additionalFilter,
                        ...updatedFilter,
                        pageNumber,
                        pageSize,
                        orderBy,
                    } as IFilter

                    props.apiCall(finalFilter).then(
                        (rsp) => {
                            if (rsp.items) {
                                const response = {
                                    rowData: rsp.items,
                                    rowCount: rsp.totalRecords,
                                }

                                itemsLen = rsp.items.length

                                updatePagingData(pageNumber, pageSize, rsp.totalRecords)
                                setTableRowLength(itemsLen)

                                if (response.rowCount === 0) {
                                    setIsLoading(false)
                                    setIsEmptyState(true)

                                    params.api.showNoRowsOverlay()
                                } else {
                                    setIsEmptyState(false)
                                    params.api.hideOverlay()
                                }
                                params.success(response)
                                setIsLoading(false)
                            }
                        },
                        (responseError) => {
                            params.api.showNoRowsOverlay()
                            defaultLogger.logError(responseError, null)
                            params.fail()
                        }
                    )
                } catch (err: any) {
                    params.api.showNoRowsOverlay()
                    setIsLoading(true)
                    defaultLogger.logError("Error on fetching data", err)
                    params.fail()
                    setIsLoading(false)
                }
            },
        }
    }

    const updatePagingData = (pageNumber: number, pageSize: number, totalRec: number) => {
        props.store.pagingStore.setTotalRecords(totalRec)
        props.store.pagingStore.setCurrentPage(pageNumber === 0 ? 1 : pageNumber)
        props.store.pagingStore.setPageSize(pageSize)
    }

    const onPageClick = (pageNum: number) => {
        pageNum = pageNum - 1
        // AgGrid is 0-indexed
        gridRef.current?.api.paginationGoToPage(pageNum)
        updateTrigger()
    }

    const onPageSizeChange = (pageSize: number) => {
        gridRef.current?.api.paginationSetPageSize(pageSize)
        gridRef.current?.api.setCacheBlockSize(pageSize)
        gridRef.current?.api.paginationGoToFirstPage()
        props.store.pagingStore.setPageSize(pageSize)
        props.store.pagingStore.setCurrentPage(1)
    }

    const onGridReady = useCallback((event: GridReadyEvent<TData>) => {
        const dataSource = getServerSideDatasource()
        event.api.setServerSideDatasource(dataSource)
        props.store.setAgGridApi(event.api, event.columnApi)
    }, [])

    const updateTrigger = () => {
        const pageNumber = gridRef.current?.api.paginationGetCurrentPage()
        const pageSize = gridRef.current?.api.paginationGetPageSize()
        const totalRecords = gridRef.current?.api.paginationGetRowCount()
        updatePagingData(
            pageNumber === undefined ? 1 : pageNumber + 1,
            pageSize === undefined ? props.store.pagingStore.pageSize : pageSize,
            totalRecords === undefined ? 1 : totalRecords
        )
    }

    const getReportOptions = useCallback(
        (params: GetContextMenuItemsParams) => {
            const reportTypes = lookupService.getShippingReqReportExportTypeId()
            delete reportTypes[lookupService.shippingReqReportExportType["pdf"].id] //remove pdf from ag grid export options
            const lookupEntries = [] as MenuItemDef[]
            for (const key in reportTypes) {
                lookupEntries.push({
                    name: `${reportTypes[key].display} Export`,
                    icon: reportTypes[key].icon,
                    action: async () => {
                        if (props.customExport) {
                            // @ts-ignore: allowed
                            const loadingNotification = enqueueSnackbar({
                                variant: "loading",
                                persist: true,
                            })
                            const reportTypeDisplayName = reportTypes[key].display
                            try {
                                setIsCreatingReport(true)
                                await props.customExport(reportTypes[key]).then(() => {
                                    setIsCreatingReport(false)
                                    closeSnackbar(loadingNotification)
                                    enqueueSnackbar({
                                        message: t.common("MOBOX_TABLE.REPORT_GENERATE_SUCCESS"),
                                        variant: "success",
                                    })
                                })
                            } catch (error) {
                                setIsCreatingReport(false)
                                closeSnackbar(loadingNotification)
                                const errorHandled = errorHandlingService.tryParseException(t.error, error)
                                if (!errorHandled) {
                                    enqueueSnackbar({
                                        message: t.common("MOBOX_TABLE.REPORT_GENERATE_FAILED"),
                                        variant: "error",
                                    })
                                    defaultLogger.logError(
                                        `Error on generating ${reportTypeDisplayName} report.`,
                                        error
                                    )
                                }
                            }
                        } else {
                            params.api.exportDataAsExcel()
                        }
                    },
                })
            }
            return lookupEntries
        },
        [props, t]
    )

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
            const exportOptions: (string | MenuItemDef)[] = [
                "separator",
                {
                    name: "Export",
                    subMenu: getReportOptions(params),
                },
            ]
            const result: (string | MenuItemDef)[] = [
                "copy",
                "copyWithHeaders",
                "copyWithGroupHeaders",
                "paste",
                ...exportOptions,
            ]
            return result
        },
        [getReportOptions]
    )

    const onFirstDataRender = useCallback((event: FirstDataRenderedEvent<TData>) => {
        event.api.sizeColumnsToFit()
    }, [])

    const gridOptions: GridOptions<TData> = {
        sideBar: !props.isInnerTable
            ? {
                  toolPanels: [
                      {
                          id: "columns",
                          labelDefault: "Columns",
                          labelKey: "columns",
                          iconKey: "columns",
                          toolPanel: "agColumnsToolPanel",
                          toolPanelParams: {
                              suppressPivotMode: true,
                              suppressValues: true,
                              suppressRowGroups: true,
                          },
                      },
                  ],
              }
            : undefined,
        rowModelType: "serverSide",
        columnDefs: props.columnDefinitions,
        pagination: true,
        paginationPageSize: props.store.pagingStore.pageSize,
        // Cache block size is the number of items that the Grid expects the API to return!
        cacheBlockSize: props.store.pagingStore.pageSize,
        // Reload data for previously visited pages instead of caching
        maxBlocksInCache: 1,
        serverSideInfiniteScroll: true,
        serverSideFilterOnServer: true,
        animateRows: true,
        onGridReady: onGridReady,
        onFirstDataRendered: props.isInnerTable ? undefined : onFirstDataRender,
        maxConcurrentDatasourceRequests: 1,
        suppressPaginationPanel: true,
        defaultColDef: {
            resizable: true,
        },
        noRowsOverlayComponent: (props: any) => {
            return <MobxTableViewEmptyState isInnerTable={props.isInnerTable} />
        },
        ...props.gridOptions,
    }

    const agLayout = document.querySelector(".ag-root.ag-layout-normal") as HTMLDivElement
    const rowHeight = gridOptions?.rowHeight ? gridOptions?.rowHeight : 48
    const headerHeight = 70

    useEffect(() => {
        if (agLayout) {
            agLayout.style.maxHeight = `${10 * rowHeight + headerHeight}px`
        }
    }, [tableRowLength, rowHeight, isLoading, isEmptyState, agLayout])

    return (
        <div className="u-display--width--full">
            <div className="u-mb--sml--3">
                <div
                    className={clsx(`ag-theme-material`, isLoading || isEmptyState ? "loading" : null)}
                    style={{
                        height: "100%",
                        maxHeight: 10 * rowHeight + headerHeight,
                    }}
                >
                    <AgGridReact<TData>
                        ref={gridRef}
                        gridOptions={gridOptions}
                        loadingCellRenderer={TableCellLoader}
                        getContextMenuItems={getContextMenuItems}
                        className={props.className}
                        detailRowHeight={!props.isInnerTable && props.detailRowHeight ? props.detailRowHeight : 167}
                    ></AgGridReact>
                </div>

                <MobxTablePager
                    isInnerTable={props.isInnerTable}
                    store={props.store.pagingStore}
                    onPageClick={onPageClick}
                    onPageSizeChange={onPageSizeChange}
                />
            </div>
        </div>
    )
})

export default MobxTableView
