import { useCallback, useEffect, useState } from "react"

import { lookupService, warehouseService } from "@dnr/data/services"
import { IUser } from "@dnr/data/models"
import { SearchDropdownInput } from "@dnr/ui/inputs"
import { useDidMountEffect, useRequest, useTrigger } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"

export interface InventoryOwnerSearchDropdownOption {
    userId: string
    displayText: string
    selectedUser: IUser
}

export interface InventoryOwnerSearchProps {
    required?: boolean
    className?: string
    placeholder?: string
    label?: string
    extraLabel?: string
    selectedValue?: string
    color?: string
    reset?: number
    onChange: (value: InventoryOwnerSearchDropdownOption) => void
    onReset?: () => void
    searchRole?: "superAdmin" | "backofficeAdmin" | "dealer" | "runner"
    type?: "select" | "default" | "up"
    size?: string
    width?: string
    env?: "portal" | "backoffice"
    fullWidth?: boolean
    isError?: string
    isFilter?: boolean
}

export const InventoryOwnerSearchDropdown = (props: InventoryOwnerSearchProps) => {
    const { t } = useTranslate()
    const { trigger, forceTrigger } = useTrigger()

    const [searchQuery, setSearchQuery] = useState("")
    const [selectedKey, setIsSelected] = useState(props.selectedValue)
    const [displayText, setDisplayText] = useState(props.placeholder || t.form("TRANSACTIONS.SELECT_USER"))
    const [dropdownOptions, setDropdownOptions] = useState<InventoryOwnerSearchDropdownOption[]>([])
    const [loading, setLoading] = useState(true)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleReset = () => {
        setDisplayText(props.placeholder || t.form("TRANSACTIONS.SELECT_USER"))
        setIsSelected(props.selectedValue)
        setSearchQuery("")
        setDropdownOptions([])
        forceTrigger()
    }

    useDidMountEffect(() => {
        handleReset()
    }, [props.reset])

    useDidMountEffect(() => {
        setDisplayText(props.placeholder || t.form("TRANSACTIONS.SELECT_USER"))
    }, [props.placeholder])

    const handleChange = (element: InventoryOwnerSearchDropdownOption) => {
        props.onChange(element)
        setIsSelected(element.userId)
        setDisplayText(element.displayText)
    }

    const nameFormatter = useCallback((user: IUser) => {
        if (!user) {
            return ""
        }

        return `${user.firstName} ${user.lastName} (${user.email}) CODE: ${user.code}`
    }, [])

    const { error } = useRequest(
        async () => {
            if (isDropdownOpen) {
                setLoading(true)

                const usersResponse = await warehouseService.findInventoryUsers({
                    roleId: props.searchRole ? lookupService.rolesByAbrv[props.searchRole].id : undefined,
                    pageNumber: 1,
                    pageSize: 100,
                    query: searchQuery,
                })
                if (usersResponse.items != null) {
                    const options = usersResponse.items.map((user) => {
                        return {
                            userId: user.userId,
                            selectedUser: user,
                            displayText: nameFormatter(user),
                        } as InventoryOwnerSearchDropdownOption
                    })
                    setDropdownOptions(options)
                }

                setLoading(false)
            }
        },
        [searchQuery, trigger, isDropdownOpen],
        { debounce: 700 }
    )

    return (
        <SearchDropdownInput
            isOpenedFirstTime={(isOpened) => setIsDropdownOpen(isOpened)}
            displayText={displayText}
            placeholder={props.placeholder}
            options={dropdownOptions}
            handleChange={handleChange}
            handleReset={() => {
                handleReset()
                if (props.onReset) props.onReset()
            }}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            selectedValue={selectedKey}
            selectedKey="userId"
            color={props.color}
            isLight={props.color == "light" ? false : true}
            loading={loading}
            error={error}
            {...props}
            errorMessage={props.isError}
            isFilter={props.isFilter}
        />
    )
}
