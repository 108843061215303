import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Script from "next/script"
import { routes } from "./routes"

export function App() {
    const router = createBrowserRouter(routes)
    const environment = process.env["NX_ENV"]
    const injectHeaderScript = () => {
        if (environment === "production") {
            return (
                <>
                    <Script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env["NX_PUBLIC_GA_ID"]}`}
                    />
                    <Script id="google-analytics">
                        {`  
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', '${process.env["NX_PUBLIC_GA_ID"]}')
                        `}
                    </Script>
                </>
            )
        }
        return null
    }

    return (
        <>
            {injectHeaderScript()}
            <RouterProvider router={router} />
        </>
    )
}

export default App
