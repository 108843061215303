import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"

const TrackingCardLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    const titleWidth = Math.random() * (170 - 100) + 100
    const valueWidth = Math.random() * (170 - 100) + 100

    return (
        <div ref={ref} className="c-cards c-cards--base">
            <LoaderWrapper height={40} width={loaderWidth}>
                <rect x="0" y="0" rx="6" ry="6" width={titleWidth} height="16" />
                <rect x="0" y="22" rx="6" ry="6" width={valueWidth} height="14" />
            </LoaderWrapper>
        </div>
    )
}

export default TrackingCardLoader
