import { useState } from "react"

import { useTranslate } from "@dnr/localization"
import { warehouseService } from "@dnr/data/services"
import { useRequest } from "@dnr/hooks"
import { PrimaryEmptyState } from "@dnr/ui/empty-states"
import { Link } from "react-router-dom"

interface DealInventoryDisplayProps {
    dealId: string
    productId: string
}

type DealInventory = {
    availableForShipmentCount: number
    shippingRequestedCount: number
    receivedQty: number
    inventoryQty: number
    shippedOutQty: number
}

export const DealInventoryDisplay = (props: DealInventoryDisplayProps) => {
    const { t } = useTranslate()
    const [dealInventoryReport, setDealInventoryReport] = useState<DealInventory>({
        receivedQty: 0,
        inventoryQty: 0,
        shippedOutQty: 0,
        availableForShipmentCount: 0,
        shippingRequestedCount: 0,
    })

    useRequest(
        async (options) => {
            const result: DealInventory = dealInventoryReport

            if (!props.dealId || !props.productId) {
                return
            }

            const element = await warehouseService.getProductDealInventory(
                {
                    dealId: props.dealId,
                },
                props.productId,
                options
            )

            for (const item of element.result) {
                result.receivedQty += item.totalReceivedQuantity || 0
                result.inventoryQty += item.inventoryCount || 0
                result.shippedOutQty += item.shippedOutCount || 0
                result.availableForShipmentCount += item.availableForShipmentCount || 0
                result.shippingRequestedCount += item.shippingRequestedCount || 0
            }

            setDealInventoryReport(result)
        },
        [props.dealId]
    )

    return (
        <div className="c-cards--stretch">
            <h3 className="c-cards__title u-mb--sml--2">{t.common("DEAL.DEAL_INVENTORY_OVERVIEW")}</h3>{" "}
            <div className="c-cards__content u-mt--sml--0">
                <div className="u-display--separated">
                    <span className="u-type--xsml u-type--color--text">{t.common("GENERAL.RECEIVED")}</span>
                    <span className="u-type--accent">{dealInventoryReport.receivedQty}</span>
                </div>

                <div className="u-display--separated">
                    <span className="u-type--xsml u-type--color--text">{t.common("WAREHOUSE.INVENTORY")}</span>
                    <span className="u-type--accent">{dealInventoryReport.inventoryQty}</span>
                </div>

                <div className="u-display--separated">
                    <span className="u-type--xsml u-type--color--text">
                        {t.common("WAREHOUSE.AVAILABLE_FOR_SHIPMENT")}
                    </span>
                    <span className="u-type--accent">{dealInventoryReport.availableForShipmentCount}</span>
                </div>

                <div className="u-display--separated">
                    <span className="u-type--xsml u-type--color--text">{t.common("WAREHOUSE.SHIPPING_REQUESTED")}</span>
                    <span className="u-type--accent">{dealInventoryReport.shippingRequestedCount}</span>
                </div>

                <div className="u-display--separated">
                    <span className="u-type--xsml u-type--color--text">{t.common("WAREHOUSE.SHIPPED_OUT")}</span>
                    <span className="u-type--accent">{dealInventoryReport.shippedOutQty}</span>
                </div>
            </div>
        </div>
    )
}
