import { localStorageProvider } from "@dnr/util/storage"
import { makeObservable, observable } from "mobx"

interface Banner {
    id: number
    name: string
    showTo?: string[]
    showIn: string[]
    text: (translator: (key: string, ...data: unknown[]) => string) => string
    showToLvl?: number[]
}

// WIP: to be implemented later
export class BannerStore {
    banners: Banner[] = [
        {
            id: 1,
            name: "launchPhaseBanner",
            showTo: ["Runner"],
            showIn: ["DealsList", "Dashboard"],
            showToLvl: [1],
            text: (translator) => translator("GENERAL.RUNNER_BANNER_TEXT"),
        },
        {
            id: 2,
            name: "whatsAppAnnouncementBanner",
            showIn: ["Dashboard"],
            text: () => `
                Get our announcements on WhatsApp.
                <a target="_blank" href="https://chat.whatsapp.com/CDVsu902qyS86xRz56wdVH">
                    Click here
                </a>
                to join.
            `,
        },
    ]

    constructor() {
        localStorageProvider.set("banners-dashboard", this.banners)

        makeObservable(this, {
            banners: observable,
        })
    }

    closeBanner(id: number) {
        this.banners = this.banners.filter((banner) => banner.id !== id)
        localStorageProvider.set("banners-dashboard", this.banners)
    }
}
