import {
    ApiResponseBaseModel,
    ApiResponseModel,
    Commitment,
    CommitmentFilter,
    CreateCommitment,
    IPagedCollection,
    IRequestOptions,
    LookupEntry,
    RunnerDealCommitmentStatusData,
    RunnerDealCommitmentStatusModel,
    UpdateCommitment,
} from "@dnr/data/models"
import { ServiceBase, commonService } from "@dnr/data/services"

class CommitmentsService extends ServiceBase {
    getLoggerName(): string {
        return "CommitmentsService"
    }

    async find(filter: CommitmentFilter, options?: IRequestOptions): Promise<IPagedCollection<Commitment>> {
        const response = await this.http.instance.get<IPagedCollection<Commitment>>("/commitments", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getTopCommitments(
        filter: CommitmentFilter,
        numberOfItems: number,
        options: IRequestOptions
    ): Promise<Commitment[]> {
        const response = await this.http.instance.get<Commitment[]>(`/commitments/top-commitments/${numberOfItems}`, {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async post(newCommitment: CreateCommitment): Promise<ApiResponseModel<Commitment>> {
        const response = await this.http.instance.post<ApiResponseModel<Commitment>>("/commitments", newCommitment)
        return response.data
    }

    async put(updateCommitment: UpdateCommitment): Promise<Commitment> {
        const response = await this.http.instance.put<Commitment>("/commitments", updateCommitment)
        return response.data
    }

    async updateQuantity(newQuantity: number, commitmentId: string): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>("/commitments/update-quantity", {
            commitmentId: commitmentId,
            quantity: newQuantity,
        })
        return response.data
    }

    async get(id: string, options?: IRequestOptions, include?: string): Promise<Commitment> {
        const response = await this.http.instance.get<Commitment>(`/commitments/${id}`, {
            params: {
                include,
            },
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async runnerDealCommitmentStatus(
        data: RunnerDealCommitmentStatusData,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<RunnerDealCommitmentStatusModel>> {
        const response = await this.http.instance.get<ApiResponseModel<RunnerDealCommitmentStatusModel>>(
            "/commitments/runner-deal-commitment-status",
            {
                params: data,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async cancel(id: string[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.post<ApiResponseBaseModel>("/commitments/cancel-commitments", id)
        return response.data
    }

    async downloadCommitmentsReport(filter: CommitmentFilter, type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(`/commitments/report/${type.id}`, type, filter, options)
    }
}

export default new CommitmentsService()
