import { TUpdateUser, TResponse, TUpdateAddress, ApiResponseBaseModel, TUpdateUserPaymentInfo } from "@dnr/data/models"
import { ServiceBase } from "../common-services/ServiceBase"

class UpdateUserProfileService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "UpdateUserProfileService"
    }

    async updateProfile(data: TUpdateUser, userId?: string): Promise<TResponse> {
        const res = await this.http.instance.put(`/user/profile/${userId}`, data)
        return res.data
    }

    async updateUsersPaymentInfo(data: TUpdateUserPaymentInfo, userId: string): Promise<ApiResponseBaseModel> {
        const res = await this.http.instance.patch<ApiResponseBaseModel>(
            `/user/profile/${userId}/dealer-payment-information`,
            data
        )
        return res.data
    }

    async updateAddress(data: TUpdateAddress, userId?: string): Promise<TResponse> {
        const res = await this.http.instance.put(`/user/profile/${userId}/address`, data)
        return res.data
    }
}
export default new UpdateUserProfileService()
