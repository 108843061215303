import { DateTime } from "luxon"

import {
    ApiResponseBaseModel,
    ApiResponseModel,
    IFilter,
    IPagedCollection,
    IRequestOptions,
    ProductBrand,
} from "@dnr/data/models"
import { localStorageProvider } from "@dnr/util/storage"

import { ServiceBase } from "../common-services/ServiceBase"

export interface ProductBrandCreate {
    name: string
    displayName?: string
    description?: string
    defaultDepositPercentage?: number
}

interface BrandsCacheEntry {
    createdAt: Date
    entries: ApiResponseModel<IPagedCollection<ProductBrand>>
}

class ProductsBrandService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "ProductsBrandService"
    }

    async getAllCached(
        filter?: IFilter,
        options?: IRequestOptions,
        reValidate?: boolean
    ): Promise<ApiResponseModel<IPagedCollection<ProductBrand>>> {
        if (reValidate) {
            return this.find(filter, options)
        }

        const cachedValues = localStorageProvider.get<BrandsCacheEntry>("brands-cache")

        if (
            cachedValues?.value === undefined ||
            cachedValues.value === null ||
            cachedValues.value.createdAt < DateTime.now().minus({ minutes: 10 }).toJSDate()
        ) {
            const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductBrand>>>(
                "/products/brands",
                {
                    params: filter,
                    signal: options?.abortController.signal,
                }
            )
            localStorageProvider.set("brands-cache", {
                createdAt: DateTime.now().toJSDate(),
                entries: response.data,
            })

            return response.data
        }

        return cachedValues.value.entries
    }

    async find(filter?: IFilter, options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<ProductBrand>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductBrand>>>(
            "/products/brands",
            {
                params: filter,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async get(brandId: number, options?: IRequestOptions): Promise<ApiResponseModel<ProductBrand>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductBrand>>(`/products/brands/${brandId}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async put(brandId: number, updatedProductBrand: ProductBrandCreate): Promise<ApiResponseModel<ProductBrand>> {
        const response = await this.http.instance.put<ApiResponseModel<ProductBrand>>(
            `/products/brands/${brandId}`,
            updatedProductBrand
        )
        return response.data
    }

    async post(brand: ProductBrandCreate): Promise<ApiResponseModel<ProductBrand>> {
        const response = await this.http.instance.post<ApiResponseModel<ProductBrand>>("/products/brands/", brand)
        return response.data
    }

    async delete(brandIds: number[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.delete<ApiResponseBaseModel>("/products/brands/", {
            params: {
                brandIds,
            },
        })
        return response.data
    }
}

export default new ProductsBrandService()
