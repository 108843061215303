import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"

const FilterLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref}>
            <LoaderWrapper backgroundColor="#b7c6e5" width={loaderWidth} height={55}>
                <rect x="0" y={0} rx="6" ry="6" width="445" height="48" />
                <rect x={loaderWidth - 300} y={0} rx="6" ry="6" width="300" height="48" />
            </LoaderWrapper>

            <div className="c-grid c-grid--sml--1 c-grid--med--2 c-grid--lrg--6 c-grid--gap--sml u-mb--sml--5">
                <SelectLoader />
                <SelectLoader />
                <SelectLoader />
                <SelectLoader />
                <SelectLoader />
                <SelectLoader />
            </div>
        </div>
    )
}

export default FilterLoader

const SelectLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref} className="u-width--100">
            <LoaderWrapper backgroundColor="#b7c6e5" width={loaderWidth} height={55}>
                <rect x="0" y={0} rx="6" ry="6" width="100%" height="48" />
            </LoaderWrapper>
        </div>
    )
}
