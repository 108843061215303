import React, { useState } from "react"

import { BasicMultiselectDropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { storeService } from "@dnr/data/services"

export type StoresProps = {
    placeholder?: string
    selectedValue?: string
    category?: string
    label?: string
    onChange: (selected: string[]) => void
    onReset?: () => void
    reset?: number
    fullWidth?: boolean
    isFilter?: boolean
}

export const StoresDropdown: React.FC<StoresProps> = (props) => {
    const { t } = useTranslate()

    const [productStoresOptions, setProductStoresOptions] = useState<DropdownOption[]>([])
    const [selectedDisplayText, setSelectedDisplayText] = useState<string>(
        props.placeholder || t.common("PRODUCT.SELECT_STORE")
    )

    const { loading } = useRequest(async (options) => {
        const { result: storesResponse } = await storeService.getAllCached(
            {
                pageNumber: 1,
                pageSize: 1000,
            },
            {
                abortController: options.abortController,
            }
        )
        if (storesResponse?.items != null && storesResponse.items.length > 0) {
            const storeOptions = storesResponse.items.map((m) => {
                return {
                    key: m.storeId.toString(),
                    value: m.displayName,
                    displayText: m.displayName,
                } as DropdownOption
            })
            setProductStoresOptions(storeOptions)
            if (props.selectedValue !== undefined) {
                const selectedOption = storeOptions.filter((f) => f.key === props.selectedValue)
                if (selectedOption.length !== 0) {
                    setSelectedDisplayText(selectedOption[0].displayText)
                }
            }
        }
    }, [])

    return (
        <div>
            {loading ? (
                <div className="c-loader">Loading...</div>
            ) : (
                <>
                    {props.label != null ? <label className="c-input__label">{t.form(props.label)}</label> : null}
                    <BasicMultiselectDropdown
                        resetStates={props.reset}
                        id="stores-multiselect-dropdown"
                        placeholder={selectedDisplayText}
                        selectedValue={props.selectedValue}
                        onChange={(option) => props.onChange(option)}
                        options={productStoresOptions}
                        fullWidth={props.fullWidth}
                        isFilter={props.isFilter}
                        resetFilter={props.onReset}
                    />
                </>
            )}
        </div>
    )
}
