import {
    ApiResponseBaseModel,
    CreateTrackings,
    IPagedCollection,
    IRequestOptions,
    LatestTrackingInfo,
    TrackingInfo,
    TrackingInfoFilter,
} from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class TrackingsService extends ServiceBase {
    getLoggerName(): string {
        return "TrackingsService"
    }

    async find(filter: TrackingInfoFilter, options?: IRequestOptions): Promise<IPagedCollection<TrackingInfo>> {
        const response = await this.http.instance.get<IPagedCollection<TrackingInfo>>("tracking", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getLatest(numOfItems: string, ownerId?: string, options?: IRequestOptions): Promise<LatestTrackingInfo[]> {
        const response = await this.http.instance.get<LatestTrackingInfo[]>("tracking/latests", {
            params: {
                numOfItems,
                ownerId,
            },
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async createTrackings(data: CreateTrackings) {
        const res = await this.http.instance.post("/tracking", data)
        return res
    }

    async updateTrackingUrl(trackingId: string, trackingUrl: string): Promise<ApiResponseBaseModel> {
        const res = await this.http.instance.patch<ApiResponseBaseModel>("/tracking/tracking-url", {
            trackingId,
            trackingUrl,
        })
        return res.data
    }

    async updateTracking(trackingId: string, otp?: string, note?: string): Promise<ApiResponseBaseModel> {
        const res = await this.http.instance.patch<ApiResponseBaseModel>("/tracking", {
            trackingId,
            otp,
            note,
        })
        return res.data
    }

    async deleteTracking(trackingIds: string[]) {
        const res = await this.http.instance.delete<ApiResponseBaseModel>("/tracking", {
            params: {
                trackingIds,
            },
        })

        return res
    }
}

export default new TrackingsService()
