import clsx from "clsx"
import React from "react"

type Props = {
    imgSrc?: string
    title?: string
    cardSize?: string
    className?: string
    children: React.ReactNode
}

export const CardHeader: React.FC<Props> = (props) => {
    return <div className={clsx("c-card__header", props.className)}>{props.children}</div>
}

// {imgSrc ? (
//     <img
//         src={imgSrc}
//         className={`c-card__header__img c-card__header__img--${
//             size ? size : 'base'
//         }`}
//         alt=""
//     />
// ) : null}

// {title ? <p>Title</p> : null}

// {size && size === 'lrg' ? (
//     <React.Fragment>$1.50</React.Fragment>
// ) : null}
