import { DeepRequired, FieldErrorsImpl, FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { Input, InputProps } from "@dnr/ui/inputs"
import { useTranslate } from "@dnr/localization"
import { useCallback, useEffect, useMemo, useState } from "react"
import { BaseTooltip } from "@dnr/ui/tooltips"

export type FormInputProps<TForm extends FieldValues> = {
    label: string
    suppressErrorMsg?: boolean
    name: Path<TForm>
    errorMessage?: string
    options?: RegisterOptions<TForm>
    type?: string
    extraLabel?: React.ReactNode
    fill?: boolean
    tooltipText?: string
    additionalComponent?: React.ReactNode
    warningMsg?: string
} & InputProps

export const FormInput = <TForm extends FieldValues>(props: FormInputProps<TForm>) => {
    const { placeholder, label, name, errorMessage, options, required, extraLabel, onChange, fill, ...inputProps } =
        props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, options)

    const nameSplitted = useMemo(() => {
        return name.split(".")
    }, [name])

    const getErrorMessage = useCallback((errors: FieldErrorsImpl<DeepRequired<TForm>>, customError?: string) => {
        if (Object.keys(errors).length === 0 && !customError) {
            return null
        }

        if (errors[name]?.message !== undefined || customError !== undefined) {
            return translateErrorMessage(errors[name]?.message?.toString()) || customError
        }
        // In case the error is in the array
        else if (nameSplitted.length > 1) {
            let finalErrorMessage
            let currentObject = undefined as any
            nameSplitted.every((nameSection) => {
                currentObject = currentObject === undefined ? errors[nameSection] : currentObject[nameSection]
                if (currentObject?.message !== undefined) {
                    finalErrorMessage = translateErrorMessage(currentObject?.message.toString())
                    return false
                }
                return true
            })

            if (finalErrorMessage !== undefined) {
                return finalErrorMessage
            }
        }

        return null
    }, [])

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }

        return err
    }

    const checkLabel = () => {
        if (label !== null && label !== undefined && label !== "") {
            return (
                <div className="u-display--flex-row-sb u-mb--sml--1">
                    <label className="c-input__label" htmlFor={props.name}>
                        {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                    {extraLabel !== null && extraLabel !== undefined ? <>{extraLabel}</> : null}
                </div>
            )
        }
        return null
    }

    return (
        <div className={`${props.type == "default" ? "" : "u-mb--sml--3"} ${fill ? "u-display--flex--fill" : null}`}>
            {props.tooltipText ? (
                <div className="u-display--separated u-display--flex--fill u-display--flex--ai--center u-mb--sml--1">
                    {checkLabel()}
                    <BaseTooltip variant="info" tooltipText={props.tooltipText} place="left-end"></BaseTooltip>
                </div>
            ) : (
                checkLabel()
            )}

            {getErrorMessage(form.formState.errors, errorMessage) !== null && !props.suppressErrorMsg ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">
                        {getErrorMessage(form.formState.errors, errorMessage)}
                    </span>
                </div>
            ) : null}

            {props.warningMsg ? (
                <div className="c-error--warning c-error--xsml u-mb--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__warning__message">{props.warningMsg}</span>
                </div>
            ) : null}

            <Input
                placeholder={placeholder != null ? t.form(placeholder) : ""}
                iserror={getErrorMessage(form.formState.errors, errorMessage) !== null}
                {...inputProps}
                {...field}
                suppressErrorMsg={props.suppressErrorMsg}
                type={props.type}
                onChange={(ev) => {
                    if (onChange) {
                        onChange(ev)
                    }
                    field.onChange(ev)
                }}
                additionalComponent={props.additionalComponent}
            />
        </div>
    )
}
