import { useEffect, useRef } from 'react'

/**
 * Used for avoiding running useEffect on initial render
 * @param callback callback to invoke inside useEffect
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useDidMountEffect = (callback: () => void, deps?: unknown[]) => {
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) callback()
        else didMount.current = true
    }, deps || [])
}