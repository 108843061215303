import React, { useState } from "react"

import { Input } from "@dnr/ui/inputs"

export interface OptionElementProps {
    id: string
    isChecked: boolean
    name: string
    onUpdate: (id: string, isChecked: boolean) => void
}

export const OptionElement: React.FC<OptionElementProps> = (props) => {
    const [isChecked, setIsChecked] = useState(false)

    const updateCheckedState = (checkedUpdate: boolean) => {
        setIsChecked(checkedUpdate)
        props.onUpdate(props.id, checkedUpdate)
    }

    return (
        <div className="option" key={props.id}>
            <Input
                type="checkbox"
                inputtype="check"
                inputsize="base"
                id={props.name}
                name={props.name}
                key={props.id}
                checked={isChecked}
                onChange={(ev) => updateCheckedState(ev.target.checked)}
            />
            <label htmlFor={props.name} className="c-input__label c-input__label--light">
                {props.name}
            </label>
        </div>
    )
}
