import { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"

export const ShippingInstructionsLoader = () => {
    return (
        <div>
            <div className="u-display--flex u-display--flex--gap--med c-modal--shipping-instructions">
                <div className="u-display--flex--fill-lrg">
                    <TitleLoader />
                    <InstructionLoader />
                </div>

                <div className="u-display--flex--fill">
                    <TitleLoader />
                    <WarehousesCardLoader />
                </div>
            </div>

            <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" />

            <ButtonLoader />
        </div>
    )
}

export default ShippingInstructionsLoader

const InstructionLoader = () => {
    return (
        <div className="c-cards__expandable">
            <LoaderWrapper width={386} height={440} backgroundColor="#b7c6e5">
                <rect x="0" y={0} rx="6" ry="6" width="386" height="12" />
                <rect x="0" y={20} rx="6" ry="6" width="300" height="12" />
                <rect x="0" y={40} rx="6" ry="6" width="100" height="12" />

                <rect x="0" y={80} rx="6" ry="6" width="386" height="12" />
                <rect x="0" y={100} rx="6" ry="6" width="286" height="12" />

                <rect x="0" y={140} rx="12" ry="12" width="240" height="85" />

                <rect x="0" y={240} rx="6" ry="6" width="386" height="12" />
                <rect x="0" y={280} rx="6" ry="6" width="100" height="12" />
                <rect x="0" y={300} rx="6" ry="6" width="386" height="12" />
                <rect x="0" y={340} rx="6" ry="6" width="300" height="12" />
                <rect x="0" y={360} rx="6" ry="6" width="260" height="12" />
                <rect x="0" y={400} rx="6" ry="6" width="320" height="12" />

                <rect x="0" y={420} rx="6" ry="6" width="320" height="12" />
            </LoaderWrapper>
        </div>
    )
}

const TitleLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref}>
            <LoaderWrapper width={loaderWidth} height={24} backgroundColor="#b7c6e5">
                <rect x="0" y={0} rx="6" ry="6" width="100" height="16" />
            </LoaderWrapper>
        </div>
    )
}

const ButtonLoader = () => {
    return (
        <LoaderWrapper width={100} height={40} backgroundColor="#b7c6e5">
            <rect x="0" y={0} rx="6" ry="6" width="100" height="40" />
        </LoaderWrapper>
    )
}

const WarehousesCardLoader = () => {
    return (
        <div className="c-cards__expandable u-mb--sml--3 u-width--100">
            <LoaderWrapper width={240} height={40} backgroundColor="#b7c6e5">
                <rect x="0" y={0} rx="6" ry="6" width="100" height="16" />
                <rect x="0" y={22} rx="6" ry="6" width="16" height="16" />
                <rect x="26" y={22} rx="6" ry="6" width="60" height="16" />
                <rect x={230} y={15} rx="6" ry="6" width="10" height="10" />
            </LoaderWrapper>
        </div>
    )
}
