import { DateTime } from "luxon"
import {
    ApiResponseModel,
    CreateWarehouse,
    IPagedCollection,
    IRequestOptions,
    IUser,
    LookupEntry,
    ProductDealInventoryShipping,
    ProductDealInventoryShippingFilter,
    ProductInventoryFilter,
    ProductInventoryShipping,
    ProductItemInventory,
    ProductItemsInventoryFilter,
    UserFilter,
    Warehouse,
    WarehouseDealersProductQty,
    WarehouseFilter,
    WarehouseInventory,
    WarehouseInventoryFilter,
    WarehousesCacheEntry,
} from "@dnr/data/models"
import { IUserBasicInfo } from "@dnr/data/models"
import { localStorageProvider } from "@dnr/util/storage"

import { ServiceBase, commonService, helperService } from "@dnr/data/services"

class WarehouseService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "WarehouseService"
    }

    async getAllCached(query?: WarehouseFilter, options?: IRequestOptions): Promise<Warehouse[]> {
        // In case there's some filtering, we'll always go to the API for the results
        if (query) {
            return (await this.find(query, options)).result.items
        }

        const cachedValues = localStorageProvider.get<WarehousesCacheEntry>("warehouses-cache")

        if (
            !cachedValues?.value?.entries ||
            cachedValues.value.createdAt < DateTime.now().minus({ minutes: 10 }).toJSDate()
        ) {
            const response = (await this.find(query, options)).result.items

            localStorageProvider.set("warehouses-cache", {
                createdAt: DateTime.now().toJSDate(),
                entries: response,
            })

            return response
        } else if (cachedValues?.value?.entries) {
            return cachedValues.value.entries
        }

        return []
    }

    async find(
        query?: WarehouseFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<Warehouse>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<Warehouse>>>("/warehouses", {
            params: query,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async get(warehouseId: string, options?: IRequestOptions): Promise<Warehouse> {
        const response = await this.http.instance.get<Warehouse>(`/warehouses/${warehouseId}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async put(warehouseId: string, updatedWarehouse: CreateWarehouse): Promise<Warehouse> {
        const response = await this.http.instance.put<Warehouse>(`/warehouses/${warehouseId}`, updatedWarehouse)
        return response.data
    }

    async post(warehouse: CreateWarehouse): Promise<Warehouse> {
        const response = await this.http.instance.post<Warehouse>("/warehouses", warehouse)
        return response.data
    }

    async delete(warehouseId: string): Promise<void> {
        const response = await this.http.instance.delete(`/warehouses/${warehouseId}`)
        return response.data
    }

    async getWarehouseItemsOwners(
        warehouseId: string,
        dealerUserName?: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IUserBasicInfo[]>> {
        const response = await this.http.instance.get<ApiResponseModel<IUserBasicInfo[]>>(
            `/warehouses/${warehouseId}/dealers`,
            {
                signal: options?.abortController.signal,
                params: dealerUserName,
            }
        )
        return response.data
    }

    async getWarehouseItemsByOwner(
        warehouseId: string,
        dealerId: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<WarehouseDealersProductQty[]>> {
        const response = await this.http.instance.get<ApiResponseModel<WarehouseDealersProductQty[]>>(
            `/warehouses/${warehouseId}/dealers/${dealerId}/product-inventory`,
            {
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getProductDealInventory(
        query: ProductDealInventoryShippingFilter,
        productId: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<ProductDealInventoryShipping[]>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductDealInventoryShipping[]>>(
            `/warehouses/products/${productId}/deal-inventory-shipping-summary`,
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getProductInventory(
        query?: ProductInventoryFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ProductInventoryShipping>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductInventoryShipping>>>(
            `/warehouses/products/product-inventory-shipping-summary`,
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async downloadProductItemsInventoryReport(
        type: LookupEntry,
        query?: ProductItemsInventoryFilter,
        options?: IRequestOptions | null
    ): Promise<void> {
        return this.http.instance
            .get<File>(`/warehouses/product-items-inventory/report/${type.id}`, {
                params: query,
                responseType: "blob",
                signal: options?.abortController.signal,
            })
            .then((res) => {
                helperService.downloadReport(res, type.abrv)
            })
    }

    async downloadInventoryTransferReport(type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(
            `/warehouses/inventory-transfer/report/${type.id}`,
            type,
            undefined,
            options
        )
    }

    async getProductItemsInventory(
        query?: ProductItemsInventoryFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ProductItemInventory>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductItemInventory>>>(
            "/warehouses/product-items-inventory",
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getWarehouseInventory(
        warehouseId: string,
        query?: WarehouseInventoryFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<WarehouseInventory>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<WarehouseInventory>>>(
            `/warehouses/${warehouseId}/inventory`,
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async downloadWarehouseInventoryReport(
        type: LookupEntry,
        warehouseId: string,
        query?: WarehouseInventoryFilter,
        options?: IRequestOptions | null
    ): Promise<void> {
        return commonService.generateReport(
            `/warehouses/${warehouseId}/inventory/report/${type.id}`,
            type,
            query,
            options
        )
    }

    async findInventoryUsers(filter: UserFilter, options?: IRequestOptions | null): Promise<IPagedCollection<IUser>> {
        const response = await this.http.instance.get<IPagedCollection<IUser>>("/warehouses/inventory-owners", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }
}

export default new WarehouseService()
