import React, { useState } from "react"
import { GlobalLoaderActionsContext, GlobalLoaderContext } from "./GlobalLoaderContext"

type Props = {
    children: React.ReactNode
}

export const GlobalLoaderContextProvider: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)

    return (
        <GlobalLoaderActionsContext.Provider
            value={{
                setLoading: setLoading,
            }}
        >
            <GlobalLoaderContext.Provider value={{ loading }}>{props.children}</GlobalLoaderContext.Provider>
        </GlobalLoaderActionsContext.Provider>
    )
}
