import { DetailedHTMLProps, forwardRef, ImgHTMLAttributes } from "react"

export type ImageProps = {
    size?: number
    clickable?: boolean
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => (
    <img
        ref={ref}
        width={props.size}
        height={props.size}
        className={`${props.clickable ? "c-img--clickable" : null}`}
        {...props}
    />
))
