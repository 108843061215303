import { AuthStore, BannerStore } from "@dnr/state/membership"

export class RootStore {
    authStore: AuthStore
    bannerStore: BannerStore

    constructor() {
        this.authStore = new AuthStore()
        this.bannerStore = new BannerStore()
    }
}

export default new RootStore()
