import { useTranslate } from "@dnr/localization"
import clsx from "clsx"
import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useState } from "react"

export type InputProps = {
    inputsize?: string
    inputtype?: string
    extendClassName?: string
    iserror?: boolean
    color?: string
    label?: string
    type?: string
    searchInputWidth?: "med" | "lrg"
    name?: string
    disabled?: boolean
    inline?: boolean
    trim?: boolean
    searchTooltipText?: string
    additionalComponent?: React.ReactNode
    suppressErrorMsg?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { t } = useTranslate()

    const { iserror, extendClassName, disabled, label, searchInputWidth, suppressErrorMsg, ...otherProps } = props

    const checkLabel = () => {
        if (label !== null && label !== undefined && label !== "") {
            return (
                <div className="u-display--flex-row-sb u-mb--sml--1">
                    <label className="c-input__label" htmlFor={props.name}>
                        {t.form(label)} {props.required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                </div>
            )
        }
        return null
    }

    return props.inputtype !== "checkbox" ? (
        <React.Fragment>
            {checkLabel()}
            {props.inputtype !== "search" ? (
                <>
                    {!props.additionalComponent ? (
                        <input
                            id={props.name}
                            autoComplete="off"
                            className={clsx(
                                "c-input",
                                props.color ? `c-input--${props.color}` : "",
                                props.inputsize ? `c-input--${props.inputsize}` : "",
                                props.type === "number" ? "c-input--number" : "",
                                props.type === "percent" ? "c-input--percent" : "",
                                props.type === "date" ? "date" : "",
                                props.inputtype ? `c-input--${props.inputtype}` : "",
                                iserror && !suppressErrorMsg ? "c-input--error" : "",
                                extendClassName ? extendClassName : ""
                            )}
                            ref={ref}
                            disabled={disabled}
                            {...otherProps}
                        />
                    ) : (
                        <div className="u-display--flex u-display--flex--fill u-display--flex--jc--sb u-display--flex--ai--center u-display--flex--gap--sml">
                            <input
                                id={props.name}
                                autoComplete="off"
                                className={clsx(
                                    "c-input",
                                    props.color ? `c-input--${props.color}` : "",
                                    props.inputsize ? `c-input--${props.inputsize}` : "",
                                    iserror && !suppressErrorMsg ? "c-input--error" : "",
                                    extendClassName ? extendClassName : ""
                                )}
                                ref={ref}
                                disabled={disabled}
                                {...otherProps}
                            />
                            {props.additionalComponent}
                        </div>
                    )}
                </>
            ) : (
                <div
                    className={`c-input--search__wrapper ${
                        searchInputWidth == "med" ? "c-input--search__wrapper--med" : null
                    } ${searchInputWidth == "lrg" ? "c-input--search__wrapper--lrg" : null}`}
                    title={`${props.searchTooltipText ? props.searchTooltipText : "Search"}`}
                >
                    <i className="u-icon u-icon--med u-icon--search u-ml--sml--3"></i>
                    <input
                        id={props.name}
                        autoComplete="off"
                        title={props.placeholder}
                        className={clsx(
                            "c-input search",
                            props.color ? `c-input--${props.color}` : "",
                            props.inputsize ? `c-input--${props.inputsize}` : "",
                            props.type === "number" ? "c-input--number" : "",
                            props.type === "percent" ? "c-input--percent" : "",
                            props.type === "date" ? "date" : "",
                            props.inputtype ? `c-input--${props.inputtype}` : "",
                            iserror && !suppressErrorMsg ? "c-input--error" : "",
                            extendClassName ? extendClassName : "",
                            props.trim ? "c-input--trim" : ""
                        )}
                        ref={ref}
                        disabled={disabled}
                        {...otherProps}
                    />
                </div>
            )}
        </React.Fragment>
    ) : (
        <>
            <input
                className={clsx(
                    "c-input c-input--check",
                    props.inputsize ? `c-input--${props.inputsize}` : "",
                    iserror && !suppressErrorMsg ? "c-input--error" : "",
                    disabled ? "c-input--check--disabled" : "",
                    extendClassName ? extendClassName : ""
                )}
                ref={ref}
                {...otherProps}
                disabled={props.disabled}
            />{" "}
            <label
                htmlFor={props.name}
                className={clsx("c-input__label c-input__label--light", props.inline ? "" : "u-mb--sml--4")}
            >
                {label} {props.required ? <span className="u-type--color--error">*</span> : ""}
            </label>
        </>
    )
})
