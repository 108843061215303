import React, { useEffect, useMemo, useRef } from "react"
import { observer } from "mobx-react"

import { commonService, helperService } from "@dnr/data/services"
import { BasePlaceholder } from "@dnr/ui/placeholders"
import { useSubmitter } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"

import { TopicMessageStore } from "../stores"
import { TopicMessage } from "../models"

interface TopicMessageDisplayProps {
    store: TopicMessageStore
}

export const TopicMessageDisplay = observer((props: TopicMessageDisplayProps) => {
    const { t } = useTranslate()

    const userInfoDisplay = useMemo(() => {
        return (
            <>
                <p className="u-type--sml u-type--wgt--medium u-type--color--title">
                    {props.store.senderDisplayName(t.common)}
                </p>
                <p className="c-input__label c-input__label--light">
                    {helperService.formatDateAndTime(props.store.message.dateCreated)}
                </p>
            </>
        )
    }, [])

    const targetElementRef = useRef(null)

    useEffect(() => {
        stringToHTML(props.store.message, targetElementRef)
    }, [props.store.message])

    const stringToHTML = (messageData: TopicMessage, targetElement: any) => {
        const htmlString = messageData.fileName ? messageData.fileName : messageData.content.replace("</br>", "<br>")
        if (targetElement) {
            targetElement.current.innerHTML = htmlString
        }
    }

    const { onSubmit: downloadFile } = useSubmitter<{ path: string; fileName?: string }, void>({
        requestCallback: ({ path, fileName }) => {
            const uriEncodedPath = encodeURIComponent(path)
            const noExtensionFilename = fileName?.split(".")[0]
            return commonService.readFile(uriEncodedPath, noExtensionFilename)
        },
        onLoadingNotification: {
            show: true,
            message: t.common("GENERAL.DOWNLOADING"),
        },
        errMsg: t.error("GENERAL.FILE_DOWNLOAD_FAILED"),
        successMsg: t.common("SHIPPING_REQUESTS.FILE_DOWNLOAD_SUCCESS"),
        logErrMsg: "Error on downloading the file",
    })

    return (
        <>
            {props.store.isCurrentUsersMessage ? (
                <div className="u-display--flex u-display--flex--jc--flex-end u-display--flex--gap--sml">
                    <div className="u-type--right">{userInfoDisplay}</div>
                    <div className="c-user__avatar">
                        <BasePlaceholder size={32} />
                    </div>
                </div>
            ) : (
                <div className="general return - not current User u-display--flex u-display--flex--gap--sml">
                    <div className="c-user__avatar">
                        <BasePlaceholder size={32} />
                    </div>
                    <div>{userInfoDisplay}</div>
                </div>
            )}
            <div
                className={`c-message ${props.store.isCurrentUsersMessage ? "c-message--secondary" : ""
                    } u-display--ib u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml`}
            >
                {props.store.message.relativePath ? (
                    <i
                        className={`u-icon u-icon--base u-icon--download${props.store.isCurrentUsersMessage ? "--white" : ""
                            } u-cursor--pointer`}
                        onClick={() =>
                            props.store.message.relativePath
                                ? downloadFile({
                                    path: props.store.message.relativePath,
                                    fileName: props.store.message.fileName,
                                })
                                : null
                        }
                    />
                ) : null}
                <p ref={targetElementRef} className="u-type--sml u-type--color--text" />
            </div>
        </>
    )
})
