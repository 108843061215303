import { useCallback, useState, useEffect, DetailedHTMLProps, InputHTMLAttributes } from "react"

import { FieldValues, Path } from "react-hook-form"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { productsCategoryService } from "@dnr/data/services"
import { IFilter } from "@dnr/data/models"
import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    name: Path<TForm>
    selectedId?: number
    parentCategoryId?: number
    onUpdate: (selectedId: number) => void
    placeholder?: string
    color?: string
}

export const ProductCategoriesFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const { t } = useTranslate()

    const [productCategoriesOptions, setProductCategoriesOptions] = useState<FormDropdownOption[]>([])
    const [selectedDisplayText, setDisplayText] = useState<string>(
        props?.placeholder ? props.placeholder : t.common("PRODUCT.SELECT_CATEGORY")
    )
    const [displayChildrenOfId, setDisplayChildrenOfId] = useState<number>()
    const [categoriesWithChildren, setCategoriesWithChildren] = useState<number[]>([])

    useEffect(() => {
        if (props.placeholder) {
            setDisplayText(props.placeholder)
        }
    }, [props.placeholder])

    const { loading } = useRequest(
        async (options) => {
            const { result: categoriesResponse } = await productsCategoryService.find(
                {
                    pageNumber: 1,
                    pageSize: 100,
                    parentCategoryId: props.parentCategoryId,
                } as IFilter,
                {
                    abortController: options.abortController,
                }
            )

            const categories = categoriesResponse.items
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((item) => {
                    return {
                        key: item.categoryId.toString(),
                        value: item.name,
                        displayText: item.displayName,
                    } as FormDropdownOption
                })

            setCategoriesWithChildren(categoriesResponse.items.filter((c) => !c.isLeaf).map((c) => c.categoryId))
            setProductCategoriesOptions(categories)
        },
        [props.parentCategoryId]
    )

    const generateChildCategoryDropdown = useCallback(() => {
        return (
            <div className="u-mt--sml--2">
                <ProductCategoriesFormDropdown<TForm>
                    name={props.name}
                    key={displayChildrenOfId}
                    onUpdate={(id) => props.onUpdate(id)}
                    parentCategoryId={displayChildrenOfId}
                    placeholder={t.common("PRODUCT.SELECT_SUBCATEGORY")}
                    color="light"
                />
            </div>
        )
    }, [displayChildrenOfId])

    return (
        <div>
            {loading ? (
                "Loading"
            ) : (
                <div>
                    <FormDropdown<TForm>
                        label=""
                        name={props.name}
                        type="default"
                        color={props.color}
                        placeholder={selectedDisplayText}
                        selectedValue={props.selectedId?.toString()}
                        onUpdate={(option) => {
                            const selectedId = Number(option.key)
                            if (categoriesWithChildren.includes(selectedId)) {
                                setDisplayChildrenOfId(selectedId)
                            } else {
                                setDisplayChildrenOfId(undefined)
                            }
                            props.onUpdate(Number(option.key))
                        }}
                        options={productCategoriesOptions}
                    />
                    <div>{displayChildrenOfId !== undefined ? generateChildCategoryDropdown() : ""}</div>
                </div>
            )}
        </div>
    )
}
