import { useTranslate } from "@dnr/localization"

interface BasicNotificationProps {
    type: string
    message: string
    size: string
}

export const BasicNotification: React.FC<BasicNotificationProps> = (props) => {
    const { t } = useTranslate()

    return (
        <div className={`c-notification c-notification--${props.type} c-notification--${props.size}`}>
            <div className={`u-display--flex u-display--flex--ai--center u-display--flex--gap--sml`}>
                <i className={`u-icon u-icon--base u-icon--${props.type}`}></i>
                <p className={`c-notification__message c-notification__message--${props.type}`}>{props.message}</p>
            </div>
        </div>
    )
}
