import { ModalStore } from "./ModalStore"

export class RootModalStore {
    modalStore: ModalStore

    constructor() {
        this.modalStore = new ModalStore()
    }
}

export default new RootModalStore()
