import { ResetPass, TResponse } from "@dnr/data/models"
import { ServiceBase } from "../common-services/ServiceBase"

class ResetPasswordService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "ResetPasswordService"
    }

    async resetPassword(data: ResetPass, userId?: string): Promise<TResponse> {
        const res = await this.http.instance.post(`/user/profile/${userId}/change-password`, data)
        return res.data
    }
}
export default new ResetPasswordService()
