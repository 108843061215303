import { DependencyList, useEffect, useState } from "react"

/**
 * Used for triggering custom events.
 * @param deps dependency list for trigger state change.
 * @returns trigger state for triggering event and forceTrigger() to dynamically invoke trigger state change.
 */

export const useTrigger = (deps?: DependencyList) => {
    const [trigger, setTrigger] = useState<number>(0)

    const forceTrigger = () => {
        setTrigger((prev) => prev + 1)
    }

    const dependencies = deps ? [...deps] : []

    useEffect(() => {
        if (dependencies.length !== 0) setTrigger((prev) => prev + 1)
    }, dependencies)

    return {
        trigger,
        forceTrigger,
    }
}
