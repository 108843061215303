import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface TopicTypeDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    label?: string
    reset?: number
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    onReset?: () => void
    isFilter?: boolean
}

export const TopicTypeDropdown = (props: TopicTypeDropdownProps) => {
    const { t } = useTranslate()
    const topicTypes = lookupService.getTopicTypeId()

    const topicTypeOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in topicTypes) {
            if (topicTypes[key].abrv !== "shipping-request") {
                lookupEntries.push({
                    displayText: topicTypes[key].display,
                    key: topicTypes[key].id.toString(),
                    value: topicTypes[key].id.toString(),
                    icon: topicTypes[key].icon,
                })
            }
        }
        return lookupEntries
    }, [])

    return (
        <div className={`${props.env == "backoffice" ? "u-display--flex-col-fill" : ""}`}>
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">{t.form(props.label)}</label>
                </div>
            ) : null}
            <Dropdown
                resetStates={props.reset}
                selectedValue={props.selectedValue}
                id="topic-type"
                type="select"
                size="base"
                width={props.width}
                placeholder={props.placeholder}
                onChange={(option) => props.onChange(option)}
                options={topicTypeOptions}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </div>
    )
}
