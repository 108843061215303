import { useTranslate } from "@dnr/localization"

import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react"

export type CheckboxInputProps = {
    inputsize?: "sml" | "base" | "med" | "lrg"
    extendClassName?: string
    iserror?: boolean
    color?: string
    label?: string
    name?: string
    type?: string
    errorMessage?: string
    textColor?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Checkbox = forwardRef<HTMLInputElement, CheckboxInputProps>((props, ref) => {
    const { iserror, placeholder, label, name, errorMessage, required, textColor, ...inputProps } = props
    const { t } = useTranslate()
    return (
        <>
            <input
                className={`c-input ${
                    props.type == "nolabel" ? "c-input--check c-input--check--no-label" : " c-input--check"
                }`}
                id={props.name}
                placeholder={placeholder != null ? t.form(placeholder) : ""}
                ref={ref}
                type="checkbox"
                {...inputProps}
            />

            <label
                htmlFor={props.name}
                className={`${
                    props.type == "nolabel"
                        ? "c-input__label c-input__label--none"
                        : "c-input__label c-input__label--light "
                } ${textColor == "light" ? "u-type--color--light-sec" : null} ${
                    props.extendClassName ? props.extendClassName : null
                }`}
            >
                {props.label} {required ? <span className="u-type--color--error">*</span> : ""}
            </label>
        </>
    )
})
