import { FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { Input, InputProps } from "@dnr/ui/inputs"
import { useTranslate } from "@dnr/localization"
import { Link } from "react-router-dom"

export type FromCheckboxProps<TForm extends FieldValues> = {
    label?: string
    name: Path<TForm>
    errorMessage?: string
    labelWithLink?: boolean
    options?: RegisterOptions<TForm>
} & InputProps

export const FormCheckbox = <TForm extends FieldValues>(props: FromCheckboxProps<TForm>) => {
    const { placeholder, label, labelWithLink, name, errorMessage, options, required, onChange, ...inputProps } = props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, options)

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }

        return err
    }

    return (
        <div>
            {" "}
            {form.formState.errors[name]?.message ? (
                <div className="c-input__error u-mb--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">
                        {translateErrorMessage(form.formState.errors[name]?.message?.toString())}
                    </span>
                </div>
            ) : null}
            <Input
                id={props.name}
                placeholder={placeholder != null ? t.form(placeholder) : ""}
                type="checkbox"
                {...inputProps}
                {...field}
                inputtype="check"
                onChange={(ev) => {
                    if (onChange) {
                        onChange(ev)
                    }
                    field.onChange(ev)
                }}
            />
            {label != null ? (
                <label htmlFor={props.name} className="c-input__label c-input__label--light">
                    {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                </label>
            ) : null}
            {labelWithLink ? (
                <label htmlFor={props.name} className="c-input__label c-input__label--light">
                    By signing up, I accept the Deal&Runner{" "}
                    <Link target="_blank" rel="noopener noreferrer" to="https://www.dealandrunner.com/terms-of-use/">
                        Terms and Conditions
                    </Link>{" "}
                    and acknowledge the{" "}
                    <Link to="https://www.dealandrunner.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </Link>{" "}
                    {required ? <span className="u-type--color--error">*</span> : ""}
                </label>
            ) : null}
        </div>
    )
}
