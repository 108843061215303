import React, { useState, useCallback } from "react"

import { observer } from "mobx-react"
import { ColDef } from "ag-grid-community"

import { MobxTableView, TableStore } from "@dnr/features/shared/tables"
import { useTranslate } from "@dnr/localization"
import { helperService, lookupService } from "@dnr/data/services"
import { IFilter, IPagedCollection, ProductStatusesFilter } from "@dnr/data/models"
import { warehouseItemsService } from "@dnr/features/shared/warehouse"
import { usePortalEnvContext } from "@dnr/hooks"
import { Link } from "react-router-dom"

interface DealInventoryPaymentDisplayProps {
    tableStore: TableStore<ProductStatusesFilter>
}

interface RowData {
    dealId: number
    dateCreated: Date
    dateUpdated: Date
    commitmentId: string
    product: string
    amount: string
    status: string
    deposit?: string
    systemFee: string
    fulfillmentFee: string
    runnersProceeds: string
}

export const DealInventoryPaymentDisplay = observer((props: DealInventoryPaymentDisplayProps) => {
    const { t } = useTranslate()
    const { currencyFormatter } = helperService
    const app = usePortalEnvContext()

    const getDealerPaymentStatus = useCallback((id: number) => {
        return lookupService.getDealerPaymentStatusId()[id].display
    }, [])

    const apiCall = async (filter: IFilter) => {
        const rsp = await warehouseItemsService.getDealerProductStatus(filter)

        let responseItems = [] as RowData[]
        let totalItemCount = 0

        if (rsp.result !== undefined && rsp.result !== null && rsp.result.items.length > 0) {
            responseItems = rsp.result.items.map(
                (item) =>
                    ({
                        product: item.productName,
                        imageUrl: item.imageUrl,
                        dealId: item.dealNumericalId,
                        amount: currencyFormatter(item.offeredPricePerItem),
                        dateCreated: item.dateCreated,
                        dateUpdated: item.dateUpdated,
                        commitmentId: item.commitmentId,
                        fulfillmentFee: currencyFormatter(item.fulfillmentFeePerItem),
                        runnersProceeds: currencyFormatter(item.runnersProceeds),
                        systemFee: currencyFormatter(item.systemFeePerItem),
                        deposit: currencyFormatter(item.depositPerItem),
                        status: item.dealerPaymentStatusId ? getDealerPaymentStatus(item.dealerPaymentStatusId) : "-",
                        productId: item.productId,
                    } as RowData)
            )

            totalItemCount = rsp.result.totalRecords
        }
        return {
            items: responseItems,
            totalRecords: totalItemCount,
        } as IPagedCollection<RowData>
    }

    const [columnDefs] = useState<ColDef<RowData>[]>([
        {
            field: "product",
            headerValueGetter: () => t.common("PRODUCT.TITLE"),
            cellRenderer: (data: any) => {
                return (
                    <div className="u-display--flex u-type--ellipsis u-display--flex--ai--center">
                        <div className={`${!data.data.imageUrl ? "c-img--overlay" : null}`}>
                            <img
                                src={
                                    data.data.imageUrl ? data.data.imageUrl : "../../assets/img/placeholders/pl-big.png"
                                }
                                alt={data.data.productName}
                                className="c-img--avatar u-mr--sml--2"
                            />
                        </div>
                        <Link
                            className="c-btn c-btn--basic c-btn--basic--secondary"
                            to={`/app/products/view/${data.data.productId}`}
                        >
                            {data.value}
                        </Link>
                    </div>
                )
            },
            flex: 1,
            minWidth: 160,
        },
        {
            field: "deposit",
            headerValueGetter: () => t.common("GENERAL.DEPOSIT"),
            minWidth: 140,
        },
        {
            field: "systemFee",
            headerValueGetter: () => t.common("GENERAL.SYSTEM_FEE"),
            minWidth: 140,
        },
        {
            field: "fulfillmentFee",
            headerValueGetter: () => t.common("PRODUCT.FULFILLMENT_FEE"),
            minWidth: 160,
        },
        {
            field: "runnersProceeds",
            headerValueGetter: () =>
                app === "smd" ? t.common("DEAL.SHOPPER_PROCEEDS") : t.common("DEAL.RUNNER_PROCEEDS"),
            minWidth: 170,
        },
        {
            field: "amount",
            headerValueGetter: () => t.common("DEAL.OFFERED_PRICE"),
            minWidth: 160,
        },
        {
            field: "dateCreated",
            headerValueGetter: () => t.common("BACKOFFICE.FINANCES.RECEIVED_AT"),
            valueFormatter:
                // @ts-ignore: allowed
                helperService.getTableDateTimeFormatter<PaymentsReportsRowData>,
            sortable: true,
            sort: "desc",
            maxWidth: 160,
            minWidth: 160,
        },
        {
            field: "status",
            headerValueGetter: () => t.common("SHIPPING_REQUESTS.STATUS"),
            minWidth: 120,
        },
        {
            field: "dateUpdated",
            headerValueGetter: () => t.common("GENERAL.LAST_UPDATED"),
            valueFormatter:
                // @ts-ignore: allowed
                helperService.getTableDateTimeFormatter<PaymentsReportsRowData>,
            maxWidth: 160,
            minWidth: 160,
        },
    ])

    return (
        <MobxTableView<RowData, ProductStatusesFilter>
            apiCall={(f) => apiCall(f)}
            store={props.tableStore}
            columnDefinitions={columnDefs}
            externalFilters={[]}
        />
    )
})
