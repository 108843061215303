import { useCallback } from "react"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-material.css"

import "ag-grid-enterprise"
import { observer } from "mobx-react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"

import { PagingStore } from "./PagingStore"

export interface MobxTablePagerProps {
    store: PagingStore
    isInnerTable?: boolean
    onPageClick: (pageNum: number) => void
    onPageSizeChange: (pageSize: number) => void
}

export const MobxTablePager: React.FC<MobxTablePagerProps> = observer((props) => {
    const { t } = useTranslate()
    const generatePageNumber = useCallback(() => {
        const pageStart = props.store.currentPage - 2 > 0 ? props.store.currentPage - 2 : 1

        const finalItems = []

        for (let i = pageStart; i < pageStart + 5; i++) {
            if (i <= props.store.totalPages) {
                finalItems.push(
                    <button
                        key={i}
                        onClick={(ev) => props.onPageClick(i)}
                        disabled={i === props.store.currentPage}
                        className={
                            i === props.store.currentPage
                                ? "c-pagination__page c-pagination__page--active"
                                : "c-pagination__page"
                        }
                    >
                        {i}
                    </button>
                )
            }
        }
        return finalItems
    }, [props.store.currentPage, props.store.totalPages])

    const changePageSize = (pageSize: number) => {
        props.onPageSizeChange(pageSize)
    }

    return (
        <div>
            <div className={`c-ag__footer ${props.isInnerTable ? "c-ag__footer--sml" : ""}`}>
                {!props.store.isPagingDisabled ? (
                    <>
                        <div className="c-ag__pager__wrapper">
                            {!props.isInnerTable ? (
                                <Dropdown
                                    id="page-size"
                                    placeholder={props.store.pageSize.toString() || t.common("GENERAL.PAGE_SIZE")}
                                    onChange={(option) => {
                                        changePageSize(Number(option.key))
                                    }}
                                    selectedValue={props.store.pageSize.toString()}
                                    options={props.store.availablePageSizes.map((opt) => {
                                        return {
                                            key: opt.toString(),
                                            value: opt.toString(),
                                            displayText: opt.toString(),
                                        } as DropdownOption
                                    })}
                                    size="sml"
                                    direction="up"
                                />
                            ) : null}
                            <div className="c-ag__pager__entries">
                                <div className="c-ag__pager__entries--current">
                                    {props.store.currentPageStart} - {props.store.currentPageEnd}
                                </div>{" "}
                                of {props.store.totalRecords}
                            </div>
                        </div>
                        <div className="c-ag__pager">
                            <button
                                className="c-btn--basic"
                                onClick={() => props.onPageClick(1)}
                                disabled={props.store.currentPage === 1}
                            >
                                <i className="u-icon u-icon--sml u-icon--arrow-first"></i>
                            </button>
                            <button
                                className="c-btn--basic"
                                onClick={() => props.onPageClick(props.store.currentPage - 1)}
                                disabled={props.store.currentPage === 1}
                            >
                                <i className="u-icon u-icon--sml u-icon--arrow-prev"></i>
                            </button>
                            <div className="c-ag__pager__pages">{generatePageNumber()}</div>
                            <button
                                className="c-btn--basic"
                                onClick={() => props.onPageClick(props.store.currentPage + 1)}
                                disabled={props.store.isLastPage}
                            >
                                <i className="u-icon u-icon--sml u-icon--arrow-next"></i>
                            </button>
                            <button
                                className="c-btn--basic"
                                onClick={() => props.onPageClick(props.store.totalPages)}
                                id="btLast"
                                disabled={props.store.isLastPage}
                            >
                                <i className="u-icon u-icon--sml u-icon--arrow-last"></i>
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
})
