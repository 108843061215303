import { DateTime } from "luxon"

import { ApiResponseModel, IFilter, IPagedCollection, IRequestOptions, Store } from "@dnr/data/models"
import { localStorageProvider } from "@dnr/util/storage"

import { ServiceBase } from "../common-services/ServiceBase"

export interface ProductStoreCreate {
    name: string
    displayName?: string
    description?: string
    isActive: boolean
    minCommissionPercentage?: number
    imageUrl?: string
    websiteUrl?: string
}

interface StoresCacheEntry {
    createdAt: Date
    entries: ApiResponseModel<IPagedCollection<Store>>
}

class StoreService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "StoreService"
    }

    async getAllCached(
        filter?: IFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<Store>>> {
        const cachedValues = localStorageProvider.get<StoresCacheEntry>("stores-cache")

        if (
            cachedValues?.value === undefined ||
            cachedValues.value === null ||
            cachedValues.value.createdAt < DateTime.now().minus({ minutes: 10 }).toJSDate()
        ) {
            const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<Store>>>(
                "/products/stores",
                { params: filter, signal: options?.abortController.signal }
            )
            localStorageProvider.set("stores-cache", {
                createdAt: DateTime.now().toJSDate(),
                entries: response.data,
            })

            return response.data
        }

        return cachedValues.value.entries
    }

    async find(filter: IFilter, options?: IRequestOptions): Promise<ApiResponseModel<IPagedCollection<Store>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<Store>>>("/products/stores", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async put(storeId: number, updatedProductStore: ProductStoreCreate): Promise<Store> {
        const response = await this.http.instance.put<Store>(`/products/stores/${storeId}`, updatedProductStore)
        return response.data
    }

    async post(store: ProductStoreCreate): Promise<ApiResponseModel<Store>> {
        const response = await this.http.instance.post<ApiResponseModel<Store>>("/products/stores/", store)
        return response.data
    }

    async delete(storeIds: number[]): Promise<void> {
        const response = await this.http.instance.delete("/products/stores/", {
            params: {
                storeIds,
            },
        })
        return response.data
    }
}

export default new StoreService()
