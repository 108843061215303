import {
    ApiResponseBaseModel,
    ApiResponseModel,
    CreateShippingRequest,
    IPagedCollection,
    IRequestOptions,
    LookupEntry,
    ShippingRequest,
    ShippingRequestQuery,
    UpdateShippingRequest,
    UpdateShippingRequestItems,
} from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"
import commonService from "../common-services/commonService"

class ShippingRequestService extends ServiceBase {
    getLoggerName(): string {
        return "Shipping Requests Service"
    }

    async find(
        filter: ShippingRequestQuery,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ShippingRequest>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ShippingRequest>>>(
            "/shipping-requests",
            {
                params: filter,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async get(
        shippingRequestId: string,
        includeOwnerInfo: boolean,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<ShippingRequest>> {
        const response = await this.http.instance.get<ApiResponseModel<ShippingRequest>>(
            `/shipping-requests/${shippingRequestId}`,
            {
                params: {
                    includeOwnerInfo,
                },
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async create(shippingRequestData: CreateShippingRequest): Promise<ApiResponseModel<ShippingRequest>> {
        const response = await this.http.instance.post<ApiResponseModel<ShippingRequest>>(
            "/shipping-requests",
            shippingRequestData
        )
        return response.data
    }

    async update(
        shippingId: string,
        shippingRequestData: UpdateShippingRequest
    ): Promise<ApiResponseModel<ShippingRequest>> {
        const response = await this.http.instance.put<ApiResponseModel<ShippingRequest>>(
            `/shipping-requests/${shippingId}`,
            shippingRequestData
        )
        return response.data
    }

    async updateItem(shippingRequestItemsData: UpdateShippingRequestItems): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.put<ApiResponseBaseModel>(
            "/shipping-requests/items",
            shippingRequestItemsData
        )
        return response.data
    }

    async uploadFile(shippingReqId: string, file: FormData): Promise<ApiResponseModel<ShippingRequest>> {
        const response = await this.http.instance.put<ApiResponseModel<ShippingRequest>>(
            `/shipping-requests/${shippingReqId}/images`,
            file
        )
        return response.data
    }

    async generateInvoice(shippingReqId: string, type: LookupEntry): Promise<void> {
        return commonService.generateReport(`/shipping-requests/${shippingReqId}/generate-shipping-request-invoice-report`, type)
    }

    async delete(shippingIds: string[] | string): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.delete<ApiResponseBaseModel>("/shipping-requests", {
            params: {
                shippingIds,
            },
        })
        return response.data
    }
}

export default new ShippingRequestService()
