import { useState, useCallback } from "react"

import { FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { useTranslate } from "@dnr/localization"
import { FormOptionValue } from "./FormOptions"

export type MultiSelectFormOptions<TForm extends FieldValues> = {
    label: string
    name: Path<TForm>
    errorMessage?: string
    options?: RegisterOptions<TForm>
    type?: string
    required?: boolean
    values: FormOptionValue[]
    onOptionSelect: (value: number[]) => void
}

export const MultiSelectFormOptions = <TForm extends FieldValues>(props: MultiSelectFormOptions<TForm>) => {
    const { label, name, errorMessage, options, required, values, onOptionSelect, ...inputProps } = props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, options)

    const [selectedOption, setSelectedOption] = useState<number[]>([])

    const translateErrorMessage = (err?: string) => {
        if (err != null) {
            return t.error(err)
        }
        return err
    }

    const handleSelectedOption = (selectedOpt: number) => {
        let result: number[]
        if (selectedOption.includes(selectedOpt)) {
            setSelectedOption((oldArray) => oldArray.filter((item) => item !== selectedOpt))
            result = selectedOption.filter((item) => item !== selectedOpt)
        } else {
            setSelectedOption((oldArray) => [...oldArray, selectedOpt])
            result = [...selectedOption, selectedOpt]
        }
        onOptionSelect(result)
    }

    const isActive = useCallback(
        (currentValue: number) => {
            return selectedOption.includes(currentValue)
        },
        [selectedOption]
    )

    return (
        <div className={`${props.type === "default" ? "" : "u-mb--sml--2"}`}>
            <div>
                {label != null ? (
                    <label className="c-input__label">
                        {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                ) : null}
            </div>
            {form.formState.errors[name]?.message ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">
                        {translateErrorMessage(form.formState.errors[name]?.message?.toString())}
                    </span>
                </div>
            ) : null}

            <div className="c-pagination u-display--flex--gap--xsml u-mt--sml--3">
                {values.map((item, index) => (
                    <div
                        key={index}
                        className={isActive(Number(item.value)) ? "active" : ""}
                        onClick={(e) => {
                            e.preventDefault()
                            handleSelectedOption(Number(item.value))
                        }}
                    >
                        <label
                            key={index}
                            className="c-pagination__page c-pagination__page--secondary"
                            htmlFor={item.id}
                        >
                            {item.label}
                        </label>
                        <input
                            type="radio"
                            id={item.id}
                            value={item.value}
                            className="u-display--none"
                            {...field}
                            {...inputProps}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
