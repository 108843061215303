import React from "react"

import { Input } from "@dnr/ui/inputs"
import { useTranslate } from "@dnr/localization"

export interface SearchableDropdownHeaderProp {
    updateSearchQuery: (searchQuery: string) => void
    searchQuery: string | number
    placeholder?: string
    color?: string
}

export const SearchableDropdownHeader: React.FC<SearchableDropdownHeaderProp> = (props) => {
    const { t } = useTranslate()

    return (
        <div className="c-input__wrapper c-input__wrapper--light ">
            <Input
                type="search"
                name="dropdown-search"
                inputtype="search"
                placeholder={props.placeholder ? props.placeholder : t.common("GENERAL.SEARCH")}
                value={props.searchQuery}
                inputsize="base"
                onChange={(ev) => props.updateSearchQuery(ev.target.value)}
                color={props.color}
            />
        </div>
    )
}
