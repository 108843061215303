import React from "react"

type Props = {
    children: React.ReactNode
    style?: string
    extendedClassName?: string
}

export const ButtonGroup: React.FC<Props> = (props) => (
    <div
        className={`c-btn--group ${props.style == "separated" ? "u-display--flex--jc--sb" : ""} ${
            props.extendedClassName ? props.extendedClassName : null
        }`}
    >
        {props.children}
    </div>
)
