import { BankAccData, BankAccounts, BankAccQuery, IRequestOptions } from "@dnr/data/models"
import { ServiceBase } from "../common-services/ServiceBase"

class UserBankAccService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "UserBankAccService"
    }

    async get(query?: BankAccQuery, options?: IRequestOptions): Promise<BankAccounts[]> {
        const response = await this.http.instance.get<BankAccounts[]>("/bank-account-info", {
            params: query,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async create(data: BankAccData): Promise<BankAccounts> {
        const response = await this.http.instance.post<BankAccounts>("/bank-account-info", data)
        return response.data
    }

    async update(data: BankAccData, bankAccountInfoId: string): Promise<BankAccounts> {
        const response = await this.http.instance.put<BankAccounts>(`/bank-account-info/${bankAccountInfoId}`, data)
        return response.data
    }

    async delete(bankAccountInfoId: string): Promise<void> {
        await this.http.instance.delete(`/bank-account-info/${bankAccountInfoId}`)
        return
    }
}
export default new UserBankAccService()
