import { ApiResponseBaseModel, Errors } from "@dnr/data/models"
import { AxiosError } from "axios"
import { ServiceBase } from "./ServiceBase"

import { enqueueSnackbar } from "notistack"

interface LookupEntry {
    code: number
    message: string
    detailedMsg?: string
}

class ErrorHandlingService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "ErrorHandlingService"
    }

    // The function is intended for trying to parse an exception that's returned from Axios Http client
    tryParseException(
        translate: (message: string) => string,
        error?: any,
        baseResponse?: ApiResponseBaseModel,
        errorMsg?: string
    ): boolean {
        const filterUniqueErrors = (errors: Errors[]) => {
            const uniqueErrorCodes: { [key: string]: boolean } = {}

            // Filter out one error object per unique error code
            const filteredErrors = errors.filter(error => {
                if (!uniqueErrorCodes[error.errorCode]) {
                    uniqueErrorCodes[error.errorCode] = true
                    return true
                }
                return false
            })

            return filteredErrors
        }

        let errorHandled = false
        if (error) {
            const { response } = error as AxiosError<ApiResponseBaseModel>
            if (response?.data.errors && response.data.errors.length > 0) {
                const uniqueErrors = filterUniqueErrors(response.data.errors)
                uniqueErrors.forEach(errEntry => {
                    if (this.errorCodesCollection[errEntry.errorCode]) {
                        //@ts-ignore-allowed
                        enqueueSnackbar({
                            variant: "detailedError",
                            details: translate(this.errorCodesCollection[errEntry.errorCode].message),
                            message: errorMsg ? translate(errorMsg) : translate("GENERAL.SOMETHING_WENT_WRONG"),
                        })
                        errorHandled = true
                    } else if (this.warningCodesCollection[errEntry.errorCode]) {
                        enqueueSnackbar({
                            variant: "warning",
                            message: translate(this.warningCodesCollection[errEntry.errorCode].message),
                        })
                        errorHandled = true
                    }
                })
            }
        } else if (baseResponse) {
            const uniqueErrors = filterUniqueErrors(baseResponse.errors)
            uniqueErrors.forEach(errEntry => {
                if (this.errorCodesCollection[errEntry.errorCode]) {
                    enqueueSnackbar({
                        variant: "error",
                        message: translate(this.errorCodesCollection[errEntry.errorCode].message),
                    })
                    errorHandled = true
                } else if (this.warningCodesCollection[errEntry.errorCode]) {
                    enqueueSnackbar({
                        variant: "warning",
                        message: translate(this.warningCodesCollection[errEntry.errorCode].message),
                    })
                    errorHandled = true
                }
            })
        }
        return errorHandled
    }

    readonly warningCodesCollection = {
        4005000021: {
            message: "EXTERNAL_TRANSACTION.BALANCE_UPDATE_FAILED",
            code: 4005000021,
        },
    } as Record<string, LookupEntry>

    readonly errorCodesCollection = {
        // 5000000001: {
        //     message: 'GENERAL.SOMETHING_WENT_WRONG',
        //     code: 5000000001,
        // },
        4000000004: {
            message: "LOGIN.INVALID_CREDENTIALS",
            code: 4000000004,
        },
        4000000005: {
            message: "LOGIN.PHONE_NOT_CONFIRMED",
            code: 4000000005,
        },
        4000000006: {
            message: "LOGIN.EMAIL_NOT_CONFIRMED",
            code: 4000000006,
        },
        4000000007: {
            message: "LOGIN.ACCOUNT_NOT_APPROVED",
            code: 4000000007,
        },
        4043000007: {
            message: "PRODUCT.UPC_SEARCH_FAILED",
            code: 4043000007,
        },
        4005000021: {
            message: "EXTERNAL_TRANSACTION.BALANCE_UPDATE_FAILED",
            code: 4005000021,
        },
        4006000004: {
            message: "BACKOFFICE.TRACKING_NUMBER_EXISTING",
            code: 4006000004,
        },
        4000000009: {
            message: "BACKOFFICE.STORE_NOT_SELECTED",
            code: 4000000009,
        },
        4005000020: {
            message: "BACKOFFICE.NOT_ENOUGH_FUNDS",
            code: 4005000020,
        },
        4000000016: {
            message: "BACKOFFICE.DEPOSIT_PAYMENT_FAILED",
            code: 4000000016,
        },
        4000000017: {
            message: "REGISTER.PHONE_NUMBER_TOO_SHORT",
            code: 4000000017,
        },
        4000000012: {
            message: "DEAL.DEAL_PAYMENT_NOT_ENOUGH_BALANCE",
            code: 4000000012,
        },
        4000000021: {
            message: "DEAL.DEAL_OFFERED_PRICE_UPDATE_FAIL",
            code: 4000000021,
        },
        4005000035: {
            message: "DISBURSEMENTS.INVALID_LOCK_DATE",
            code: 4005000035,
        },
        4049000001: {
            message: "SHIPPING_REQUEST.INVOICE_GENERATE_FAILED",
            code: 4049000001,
        },
        4004000008: {
            message: "DEAL.DEAL_HAS_COMMITMENTS",
            code: 4004000008,
        },
        4004000019: {
            message: "DEAL.DEAL_IS_IN_PAUSED_STATUS",
            code: 4004000019,
        },
        4049000003: {
            message: "BACKOFFICE.DISBURSEMENT_GENERATE_REPORT_FAILED",
            code: 4049000003,
        },
        4003000007: {
            message: "BACKOFFICE.BRAND_NOT_REMOVABLE",
            code: 4003000007,
        },
        4006000025: {
            message: "BACKOFFICE.SAME_WAREHOUSE_ITEM",
            code: 4006000025,
        },
        4006000008: {
            message: "BACKOFFICE.SAME_PRODUCT_UPC",
            code: 4006000008,
        },
        4006000023: {
            message: "BACKOFFICE.USER_CODE_OR_IMAGE_REQUIRED",
            code: 4006000023,
        },
        4040000001: {
            message: "BACKOFFICE.USER_ACCOUNT_NOT_FOUND",
            code: 4040000001,
        },
        4000000039: {
            message: "BACKOFFICE.FAILED_TO_LOCK_USER",
            code: 4000000039,
        },
        4000000043: {
            message: "BACKOFFICE.PHONE_IN_USE",
            code: 4000000043,
        },
        4006000009: {
            message: "WAREHOUSE.NOT_ENOUGH_PRODUCTS",
            code: 4006000009,
        },
        400500040: {
            message: "DISBURSEMENTS.DO_ITEM_UPDATE_FAILED",
            code: 400500040,
        },
        4005000042: {
            message: "DISBURSEMENTS.MISSING_NEW_DISBURSEMENT_ORDER",
            code: 4005000042,
        },
        4046000003: {
            message: "SHIPPING_REQUEST.SHIPPING_REQUEST_NOT_FOUND",
            code: 4046000003,
        },
        4046000006: {
            message: "SHIPPING_REQUEST.SHIPPING_REQUEST_NOT_ENOUGH_ITEMS",
            code: 4046000006,
        },
        4000000038: {
            message: "LOGIN.ACCOUNT_DEACTIVATED",
            code: 4000000038,
        },
        4006000038: {
            message: "WAREHOUSE.WAREHOUSE_LINKED_WITH_TEMRINAL",
            code: 4006000038,
        },
        4006000039: {
            message: "WAREHOUSE.WAREHOUSE_LINKED_WITH_RT",
            code: 4006000039,
        },
        4006000040: {
            message: "WAREHOUSE.WAREHOUSE_LINKED_WITH_WH_ITEM",
            code: 4006000040,
        },
        4006000041: {
            message: "WAREHOUSE.WAREHOUSE_LINKED_WITH_WH_DEVICE",
            code: 4006000041,
        },
        4006000042: {
            message: "WAREHOUSE.TERMINAL_LINKED_WITH_RT",
            code: 4006000042
        },
        4004000035: {
            message: "TRACKINGS.DUPLICATE_TN",
            code: 4004000035,
        },
        4046000009: {
            message: "WAREHOUSE.RT_USER_ALREADY_ASSIGNED",
            code: 4046000009,
        },
        4046000002: {
            message: "WAREHOUSE.WAREHOUSE_ITEM_NOT_FOUND",
            code: 4046000002,
        },
        4046000008: {
            message: "WAREHOUSE.WAREHOUSE_ITEM_NOT_FOUND",
            code: 4046000008,
        },
        4044000001: {
            message: "WAREHOUSE.DEAL_NOT_FOUND",
            code: 4044000001
        },
        4044000004: {
            message: "WAREHOUSE.COMMITMENT_NOT_FOUND",
            code: 4044000004
        },
        4004000062: {
            message: "WAREHOUSE.UPDATE_COMMITMENT_FORBIDDEN_STATUS",
            code: 4004000062
        },
        4004000063: {
            message: "WAREHOUSE.UPDATE_COMMITMENT_STATUS_FAILED",
            code: 4004000063
        },
        4005000023: {
            message: "WAREHOUSE.FAILED_CREATE_SYSTEM_TRANSACTIONS",
            code: 4005000023
        },
        4006000026: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_FAILED",
            code: 4006000026
        },
        4006000027: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_FORBIDDEN_STATE",
            code: 4006000027
        },
        4006000028: {
            message: "WAREHOUSE.REVERSE_PAYMENT_CHANGES_FAILED",
            code: 4006000028
        },
        4006000029: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_UPDATE_FAILED",
            code: 4006000029
        },
        4006000030: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_COMMITMENT_STATUS_UPDATE_FAILED",
            code: 4006000030
        },
        4006000031: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_COMMITMENT_AND_PAYMENT_FAILED",
            code: 4006000031
        },
        4006000032: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_PAYMENT_FAILED",
            code: 4006000032
        },
        4006000033: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_WAREHOUSE_UPDATE_FAILED",
            code: 4006000033
        },
        4006000034: {
            message: "WAREHOUSE.ROLLBACK_DELETE_WAREHOUSE_ITEM_FAILED_TO_REVERSE_BOTH",
            code: 4006000034
        },
        4006000037: {
            message: "WAREHOUSE.FAILED_WAREHOUSE_ITEM_STATE_UPDATE",
            code: 4006000037
        },
        4006000043: {
            message: "WAREHOUSE.FAILED_UPDATE_SHIPPING_REQUEST_QUANTITY",
            code: 4006000043
        },
        4006000044: {
            message: "WAREHOUSE.FAILED_ROLLBACK_DELETE_WH_ITEM",
            code: 4006000044
        },
        4005000053: {
            message: "WAREHOUSE.FAILED_DELETE_DISBURSEMENT_ORDER_ITEM",
            code: 4005000053
        },
        4005000054: {
            message: "WAREHOUSE.FAILED_CREATE_ROLLBACK_DELETE_WAREHOUSE_ITEM_TRANSACTIONS",
            code: 4005000054
        },
        4005000055: {
            message: "WAREHOUSE.FAILED_CREATE_DNR_BALANCE_ACCOUNT_ENTRIES",
            code: 4005000055
        },
        4046000001: {
            message: "WAREHOUSE.RT_ID_NOT_FOUND",
            code: 4046000001
        },
        4000000050: {
            message: "GENERAL.FORMAT_NOT_ALLOWED",
            code: 4000000050
        },
        4040000003: {
            message: "GENERAL.FILE_NOT_FOUND",
            code: 4040000003
        }
    } as Record<string, LookupEntry>
}

export default new ErrorHandlingService()
