import { useState, useCallback } from "react"
import ListTitleLoader from "./ListTitleLoader"
import DealsCardLoader from "./DealsCardLoader"
import clsx from "clsx"

export const LatestDealsLoader = ({ listLength = 3 }: { listLength?: number }) => {
    const [loaderWidth, setLoaderWidth] = useState(0)
    const gridItemList = Array(listLength).fill("")

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div className="u-width--100">
            <ListTitleLoader loaderWidth={loaderWidth} />

            <div ref={ref} className={clsx("c-grid c-grid--gap--sml", `c-grid--sml--${listLength}`)}>
                {gridItemList.map(() => {
                    return <DealsCardLoader />
                })}
            </div>
        </div>
    )
}

export default LatestDealsLoader
