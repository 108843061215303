import {
    ApiResponseModel,
    IFilter,
    IPagedCollection,
    IRequestOptions,
    ProductCategory,
    ProductParentsCategory,
} from "@dnr/data/models"
import { ServiceBase } from "../common-services/ServiceBase"

class ProductsCategoryService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "ProductsCategoryService"
    }

    async find(
        filter: IFilter,
        options: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ProductCategory>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<ProductCategory>>>(
            "/products/categories",
            {
                params: filter,
                signal: options.abortController.signal,
            }
        )
        return response.data
    }

    async findById(categoryId: number, options?: IRequestOptions): Promise<ApiResponseModel<ProductCategory>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductCategory>>(
            `/products/categories/${categoryId}`,
            {
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async findParents(categoryId: number, options: IRequestOptions): Promise<ApiResponseModel<ProductParentsCategory>> {
        const response = await this.http.instance.get<ApiResponseModel<ProductParentsCategory>>(
            `/products/categories/${categoryId}/ascending`,
            {
                signal: options.abortController.signal,
            }
        )
        return response.data
    }
}

export default new ProductsCategoryService()
