import React, { useEffect } from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClick<TRef>(
    callback?: () => void,
    additionalRef?: any,
    refInitialValue?: any,
    blocker?: boolean
) {
    const ref = React.useRef<TRef>(refInitialValue)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref.current &&
                // @ts-ignore-allowed
                !ref.current.contains(event.target) &&
                (!additionalRef?.current || (additionalRef.current && !additionalRef.current.contains(event.target))) &&
                callback !== null &&
                callback !== undefined
            ) {
                if (!blocker) callback()
            }
        }
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [ref, blocker])

    return ref
}
