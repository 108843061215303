import clsx from "clsx"
import React from "react"

type Props = {
    size?: "sml" | "med" | string
    imgSrc?: string
    title?: string
    className?: string
}

export const CardFooter: React.FC<Props> = (props) => {
    const { imgSrc, title, size, className } = props

    return <div className={clsx("c-card__footer", className)}>{title ? <p>Footer</p> : null}</div>
}
