import React from "react"

import { Layout, RouteLayout } from "@dnr/ui/layouts"
import { Outlet } from "react-router-dom"

export type SecondaryLayoutProps = {
    children?: JSX.Element
}

export const SecondaryLayout: React.FC<SecondaryLayoutProps> = (props) => (
    <RouteLayout />

    // <RouteLayout innerHeader={<Breadcrumbs />}> {props.children} </RouteLayout>
    // --TODO: Breadcrumbs layout
)
