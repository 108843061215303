import React from "react"
import { Navigate, To, useLocation } from "react-router-dom"

type Props = {
    children: JSX.Element
    isAuthenticated: boolean
    navigateTo?: To
}

export const IsLoggedIn: React.FC<Props> = (props) => {
    const location = useLocation()

    if (props.isAuthenticated) {
        return (
            <Navigate to={props.navigateTo ? props.navigateTo : "/app/dashboard"} state={{ from: location }} replace />
        )
    }

    return props.children
}
