import React from "react"

export interface GlobalLoaderModel {
    loading: boolean
}

export interface GlobalLoaderActions {
    setLoading: (loading: boolean) => void
}

export const GlobalLoaderContext = React.createContext<GlobalLoaderModel>({ loading: false })
export const GlobalLoaderActionsContext = React.createContext<GlobalLoaderActions>({
    setLoading: (_) => console.warn("Not implemented"),
})
