import { useEffect } from "react"
import { Navigate, To, useLocation } from "react-router-dom"
import { observer } from "mobx-react"

import { enqueueSnackbar } from "notistack"

import { useTranslate } from "@dnr/localization"
import { AuthStore } from "@dnr/state/membership"

interface PrivateRouteProps {
    children: JSX.Element
    navigateTo?: To
    authStore: AuthStore
}

export const PrivateRoute = observer(({ authStore, children, navigateTo }: PrivateRouteProps) => {
    const location = useLocation()
    const { t } = useTranslate()

    useEffect(() => {
        if (!authStore.isAuthenticated) {
            enqueueSnackbar({
                message: t.common("GENERAL.TOKEN_EXPIRED"),
                variant: "error",
            })
            authStore.setLastVisitedPage(location.pathname)
        }
    }, [authStore.isAuthenticated])

    if (!authStore.isAuthenticated) {
        return <Navigate to={navigateTo ? navigateTo : "/login"} state={{ from: location }} replace />
    }
    return children
})
