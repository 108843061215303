import React, { useState } from "react"
import { Tooltip } from "react-tooltip"
import { TooltipPlacePosition, TooltipVariant } from "./TooltipProps"
import clsx from "clsx"

export type BaseTooltipProps = {
    size?: "sml" | "base" | "med" | "lrg"
    tooltipText: string
    place?: TooltipPlacePosition
    variant?: TooltipVariant
    className?: string
}

export const BaseTooltip: React.FC<BaseTooltipProps> = (props) => {
    const [tooltipPosition] = useState<TooltipPlacePosition>(props.place ? props.place : "bottom")
    const [tooltipVariant] = useState<TooltipVariant>(props.variant ? props.variant : "info")

    return (
        <>
            <p
                data-tooltip-id="my-tooltipId"
                data-tooltip-content={props.tooltipText}
                className={clsx(
                    "c-tag--common c-tag--sml c-tag--rounded",
                    props.variant === "info" ? `p-tooltip--info c-tag--info` : `c-tag--${props.variant}`
                )}
            >
                {props.variant === "info" ? "?" : "!"}
            </p>
            <Tooltip
                id="my-tooltipId"
                variant={tooltipVariant}
                place={tooltipPosition}
                positionStrategy="fixed"
                className={clsx(
                    "p-tooltip",
                    props.className,
                    props.variant ? `p-tooltip--${props.variant}` : "",
                    props.size ? `p-tooltip--${props.size}` : "p-tooltip--sml"
                )}
            />
        </>
    )
}
