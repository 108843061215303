import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import clsx from "clsx"

export type BaseTagProps = {
    tagtype?: string
    tagtitle?: string
    hasIcon?: boolean
    tagIcon?: React.ReactNode
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const BaseTag: React.FC<BaseTagProps> = (props) => {
    return !props.hasIcon ? (
        <p className={`c-tag c-tag--${props.tagtype}`}>{props.tagtitle}</p>
    ) : (
        <div className={`c-tag c-tag--icon c-tag--${props.tagtype}`}>{props.tagIcon}</div>
    )
}
