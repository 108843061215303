import React, { useState } from "react"

import { SearchableDropdownHeader } from "./SearchableDropdownHeader"
import { DropdownOptionCategory, DropdownOptionCategoryProps } from "./OptionCategory"
import { useOutsideClick } from "@dnr/hooks"
import clsx from "clsx"

export type SearchableDropdownProps = {
    name: string
    content: DropdownOptionCategoryProps[]
    onUpdate: (id: string, isChecked: boolean) => void
}

export const SearchableDropdown: React.FC<SearchableDropdownProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)
    const updateSearchQuery = (query: string) => {
        setSearchQuery(query)
    }

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className={clsx("c-input c-input--base c-input--select", isOpen ? "c-input--select--open" : "")}
            //@ts-ignore
            ref={innerRef}
        >
            <div
                className={clsx("c-input--select__text", isOptionSelected ? "c-input--select__text--selected" : "")}
                onClick={handleIsOpen}
            >
                {props.name}
            </div>
            <div
                className={`c-input--select__list c-input--select__list--bottom c-input--select__list--lrg ${
                    isOpen ? "isOpen" : ""
                }`}
            >
                <div className="fix-blur">
                    <SearchableDropdownHeader
                        searchQuery={searchQuery}
                        updateSearchQuery={(query) => updateSearchQuery(query)}
                    />
                    {props.content.map((cont) => {
                        return (
                            <div key={cont.id}>
                                <DropdownOptionCategory
                                    elements={cont.elements}
                                    id={cont.id}
                                    name={cont.name}
                                    searchQuery={searchQuery}
                                    onUpdate={(id, value) => props.onUpdate(id, value)}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
