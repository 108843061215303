import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from "react"

import { useTranslate } from "@dnr/localization"
import { PrimaryButton } from "@dnr/ui/buttons"
import { helperService } from "@dnr/data/services"

export type FileInputProps = {
    iserror?: boolean
    color?: string
    onFileSelected: (file: File) => void
    type?: string
    clearFileName?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
    const { t } = useTranslate()
    const { handleDrag, handleDrop } = helperService

    const [fileName, setFileName] = useState<string>()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setFileName("")
    }, [props.clearFileName])

    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            props.onFileSelected(e.target.files[0])
            setFileName(e.target.files[0].name)
        }
    }

    return (
        <>
            <div className="u-mb--sml--1">
                <label className="c-input__label">
                    {props.type == "image" ? t.common("GENERAL.UPLOAD_PRODUCT_IMAGE") : t.common("GENERAL.FILE")}
                </label>
            </div>
            <div className="c-input--file" id="form-file-upload">
                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) =>
                        handleDrop(e, (files) => {
                            props.onFileSelected(files[0])
                            setFileName(files[0].name)
                        })
                    }
                    id="drag-file-element"
                    className="c-input--file--drag-area"
                >
                    <p className="u-type--base u-type--color--title u-type--wgt--semibold">
                        {t.common("GENERAL.DRAG_DROP")}
                    </p>
                    <p className="u-type--sml u-type--color--light u-type--wgt--regular">or</p>
                    <PrimaryButton
                        onClick={() => document.getElementById("input-file-upload")?.click()}
                        size="sml"
                        haslabel
                        id="file-button"
                        type="button"
                    >
                        {t.common("GENERAL.BROWSE")}
                    </PrimaryButton>
                    <input
                        ref={inputRef}
                        type="file"
                        id="input-file-upload"
                        multiple={true}
                        onChange={handleChange}
                        hidden
                    />
                    {/* UPLOADED FILE NAME DISPLAY */}
                    <p className="u-type--base u-type--color--light u-type--wgt--regular">{fileName}</p>
                </div>
            </div>
        </>
    )
})
