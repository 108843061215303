import React, { useState } from "react"
import clsx from "clsx"

import { useTranslate } from "@dnr/localization"
import { useFocus, useOutsideClick } from "@dnr/hooks"
import { ListItemLoader } from "@dnr/features/shared/loader"
import { OutlineButton } from "@dnr/ui/buttons"

import { SearchInputProps } from "./SearchDropdownInputProps"

export const SearchDropdownInput = (props: SearchInputProps) => {
    const { t } = useTranslate()

    const [isOpen, setIsOpen] = useState(false)
    const [inputRef, setInputFocus] = useFocus()

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleIsOpen = (ev: any) => {
        if (ev.target.id === "outter-div") setIsOpen(!isOpen)
        setInputFocus()
        if (props.isOpenedFirstTime) props.isOpenedFirstTime(true)
    }

    return (
        <div>
            {props.label && (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">
                        {t.form(props.label)} {props.required ? <span className="u-type--color--error">*</span> : ""}
                    </label>

                    {props.errorMessage && (
                        <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                            <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                            <span className="c-input__error__message">{props.errorMessage}</span>
                        </div>
                    )}
                </div>
            )}

            <div
                id="outter-div"
                className={clsx(
                    "c-search__wrapper",
                    isOpen ? "isOpen" : "",
                    props.errorMessage && "isError",
                    props.isLight && "isLight"
                )}
                onClick={handleIsOpen}
                //@ts-ignore: allowed
                ref={innerRef}
            >
                <div
                    id="outter-div"
                    className={`c-input c-input--select c-input--base ${
                        props.color === "light" ? "c-input--select--light" : ""
                    }`}
                >
                    <div
                        id="outter-div"
                        className={clsx("c-search__value", props.displayText !== props.placeholder ? "isSelected" : "")}
                    >
                        {props.displayText}
                    </div>
                </div>

                <div className="c-search__content">
                    <div className="c-input__wrapper c-input__wrapper--light">
                        <div id="outter-div" className="c-input--search__wrapper">
                            <i className="u-icon u-icon--med u-icon--search u-ml--sml--3"></i>

                            <input
                                autoComplete="off"
                                type="text"
                                name="dropdown-search"
                                className="c-input c-input--base c-input--search c-input--light"
                                ref={inputRef}
                                value={props.searchQuery}
                                disabled={props.disabled}
                                placeholder={props.placeholder ? props.placeholder : t.common("GENERAL.SEARCH")}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    props.setSearchQuery(e.target.value)
                                }
                            />
                            <i
                                className="u-icon u-icon--sml u-icon--close--dark u-cursor--pointer"
                                style={props.searchQuery !== "" ? undefined : { display: "none" }}
                                onClick={() => {
                                    props.setSearchQuery("")
                                }}
                            ></i>
                        </div>
                    </div>

                    {props.loading ? (
                        <div className="u-mt--sml--5">
                            <ListItemLoader listHeight={24} />
                        </div>
                    ) : (
                        <OptionList {...props} />
                    )}

                    {!props.loading && props.isFilter ? (
                        <>
                            {" "}
                            <hr className="c-separator--color-light u-mt--sml--2 u-mb--sml--2" />
                            <OutlineButton
                                size="xsml"
                                haslabel
                                width="full"
                                onClick={() => {
                                    props.handleReset()
                                    setIsOpen(false)
                                }}
                            >
                                {t.common("GENERAL.RESET_FILTER")}
                            </OutlineButton>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const OptionList = (props: SearchInputProps) => {
    const { t } = useTranslate()
    let element = null

    if (props.error) element = <div>{t.error("GENERAL.SOMETHING_WENT_WRONG")}</div>

    if (props.options != null && props.options.length > 0) {
        element = props.options.map((item: any) => {
            return (
                <div
                    className={clsx(
                        "c-search__option",
                        props.selectedValue === item[props.selectedKey] ? "isActive" : ""
                    )}
                    key={Math.random()}
                    onClick={() => props.handleChange(item)}
                >
                    {item.displayText}
                </div>
            )
        })
    }

    if (props.searchQuery !== "" && props.options.length === 0 && !props.loading) element = <div>No Data</div>

    return (
        <div
            className={clsx("c-search__list", (props.searchQuery !== "" || props.options.length > 0) && "u-mt--sml--4")}
        >
            {element}
        </div>
    )
}
