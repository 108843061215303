import { LinkProps, NavLink, useLocation } from "react-router-dom"

import clsx from "clsx"

interface Props extends LinkProps {
    childpage?: string
    icon?: string | null
    label: string
    action?: (page?: string) => void
    type: "primary" | "secondary"
}

export const ItemLink = ({ action, children, to, icon, ...props }: Props) => {
    let isActive = false
    let current, next

    const location = useLocation()
    if (props.type === "primary") {
        isActive = location.pathname.split("/")[2] === to
    } else if (props.type === "secondary") {
        current = location.pathname.split("/")
        next = to.toString().split("/")
        isActive = current[2] === next[2] && current[3] === next[3]
    }

    const handleAction = () => {
        return action ? action(props.childpage) : null
    }

    return props.type === "primary" ? (
        <div>
            <NavLink to={to} {...props} className={clsx("c-menu__item", isActive ? "active" : "")}>
                <i className={`u-icon u-icon--base u-icon--${icon} u-mr--sml--2`} />
                <span className="c-menu__item__label">{props.label}</span>
            </NavLink>
        </div>
    ) : (
        <div onClick={handleAction}>
            <NavLink to={to} {...props} className={clsx("c-menu__list__item", isActive ? "active" : "")}>
                <span className="c-menu__item__label">{props.label}</span>
            </NavLink>
        </div>
    )
}
