import LoaderWrapper from "./LoaderWrapper"

const DealsCardLoader = ({ className }: { className?: string }) => {
    const loaderWidth = 266

    return (
        <div className={className ? className : "c-cards c-cards--xlrg u-width--100"}>
            <LoaderWrapper width={loaderWidth} height={330}>
                <rect x="0" y={0} rx="12" ry="12" width="65" height="65" />

                <rect x="75" y={0} rx="6" ry="6" width="150" height="10" />
                <rect x="75" y={18} rx="12" ry="12" width="70" height="24" />

                <rect x="0" y={75} rx="12" ry="12" width="50" height="24" />
                <rect x="60" y={75} rx="12" ry="12" width="70" height="24" />
                <rect x="140" y={75} rx="12" ry="12" width="80" height="24" />

                <rect x="0" y={110} rx="12" ry="12" width={(loaderWidth - 20) / 2} height="24" />
                <rect x={loaderWidth / 2} y={110} rx="12" ry="12" width={(loaderWidth - 20) / 2} height="24" />

                <rect x="0" y={150} rx="12" ry="12" width="100%" height="100" />
                <rect x="0" y={270} rx="6" ry="6" width="100" height="20" />
                <rect x="0" y={300} rx="6" ry="6" width="100%" height="30" />
            </LoaderWrapper>
        </div>
    )
}

export default DealsCardLoader
