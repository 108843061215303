import { action, makeObservable, observable } from "mobx"

export class RightAsideStore {
    asideIsOpen: boolean

    constructor() {
        this.asideIsOpen = false

        makeObservable(this, {
            handleAside: action,

            asideIsOpen: observable,
        })
    }

    handleAside(asideIsOpen: boolean) {
        this.asideIsOpen = !asideIsOpen
    }

    handleCloseAside() {
        this.asideIsOpen = false
    }
}
