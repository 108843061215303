import { useTranslate } from "@dnr/localization"
import { OutlineButton } from "@dnr/ui/buttons"
import { useSubmitter } from "@dnr/hooks"
import { ApiResponseBaseModel } from "@dnr/data/models"

import { topicService } from "../services"

interface TicketAssigneButtonProps {
    displayText: string
    topicId: string
    topicNumericalId: number
    loggedInUserId: string
    size?: "base" | "xsml" | "sml" | "med" | "lrg"
    width?: "" | "full"
    disabled?: boolean
    setLastUpdated?: (shippingId: string) => void
    hasIcon?: boolean
}

export const TicketAssigneButton = (props: TicketAssigneButtonProps) => {
    const { t } = useTranslate()

    const { onSubmit: assigningCallback, loading } = useSubmitter<unknown, ApiResponseBaseModel>({
        requestCallback: () =>
            topicService.updateTopic({
                id: props.topicId,
                assigneeUserId: props.loggedInUserId,
            }),
        successMsg: t.common("TICKETS.TICKET_ASSIGN_SUCCESS"),
        onSuccess: () => props.setLastUpdated && props.setLastUpdated(props.topicId),
        logErrMsg: "Error on assigning ticket",
    })

    return (
        <OutlineButton
            disabled={props.disabled || loading}
            loading={loading}
            onClick={assigningCallback}
            size={props.size}
            width={props.width}
            haslabel
            hasicon={props.hasIcon}
            icontype={props.hasIcon ? "assign" : undefined}
        >
            {props.displayText}
        </OutlineButton>
    )
}
