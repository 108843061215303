import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react"

import { ICellEditorParams } from "ag-grid-community"
import { PwhDropdown } from "./PwhDropdown"

export const PwhCellEditor = memo(
    forwardRef((props: ICellEditorParams, ref) => {
        const [value, setValue] = useState(props.value)
        const refInput = useRef<HTMLInputElement>(null)

        useEffect(() => {
            refInput.current?.focus()
        }, [])

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    return value
                },

                isCancelBeforeStart() {
                    return false
                },

                isCancelAfterEnd() {
                    return false
                },
            }
        })

        return (
            <PwhDropdown
                selectedValue={props.context.id}
                placeholder={props.context.name}
                direction="up"
                onChange={(option) => setValue(option)}
            />
        )
    })
)
