import React from "react"

export interface TagProps {
    status?: string
    fullWidth?: boolean
    clickable?: boolean
    children: React.ReactNode
}

export const Tag: React.FC<TagProps> = (props) => {
    return (
        // TODO: needs styling
        <div>{props.children}</div>
    )
}
