import { useEffect, useRef } from "react"

import { FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { TextArea, TextAreaProps } from "@dnr/ui/inputs"
import { useTranslate } from "@dnr/localization"

export type TextAreaInputProps<TForm extends FieldValues> = {
    label: string
    name: Path<TForm>
    errorMessage?: string
    fileUpload?: {
        onFileSelected: (file: File) => void
        clearFile: boolean
        displayText: string
    }
    options?: RegisterOptions<TForm>
    type?: string
} & TextAreaProps

export const TextAreaInput = <TForm extends FieldValues>(props: TextAreaInputProps<TForm>) => {
    const { placeholder, label, name, errorMessage, options, required, fileUpload, ...inputProps } = props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, options)

    const fileInputRef = useRef<HTMLInputElement>(null)

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }

        return err
    }

    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            fileUpload?.onFileSelected && fileUpload.onFileSelected(e.target.files[0])
        }
    }

    useEffect(() => {
        if (fileUpload && fileUpload.clearFile && fileInputRef.current !== null) {
            fileInputRef.current.value = ""
        }
    }, [fileUpload?.clearFile])

    return (
        <div className={`${props.type == "default" ? "" : "u-mb--sml--3"}`}>
            {label != null ? (
                <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml u-mb--sml--1">
                    <label className="c-input__label" htmlFor={props.name}>
                        {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                    {fileUpload ? (
                        <>
                            <p
                                onClick={() => document.getElementById("input-file-upload")?.click()}
                                className="c-btn c-btn--underline c-btn--underline--sml u-type--xsml"
                            >
                                {fileUpload.displayText}
                            </p>

                            <input
                                ref={fileInputRef}
                                onChange={handleChange}
                                type="file"
                                id="input-file-upload"
                                multiple={true}
                                hidden
                            />
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ) : null}

            {form.formState.errors[name]?.message ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">
                        {translateErrorMessage(form.formState.errors[name]?.message?.toString())}
                    </span>
                </div>
            ) : null}

            <TextArea
                placeholder={placeholder != null ? t.form(placeholder) : ""}
                iserror={form.formState.errors[name]?.message ? true : false}
                {...inputProps}
                {...field}
            />
        </div>
    )
}
